<template>
  <div
    class="chip"
    @mouseover="hoverOnChip = true"
    @mouseleave="hoverOnChip = false"
  >
    <div class="bots">
      <v-list-item
        v-for="(bot, index) in game.bots"
        :key="index"
        link
        :href="`${websiteUrl}/users/${bot.username}`"
        :style="`background: center / cover no-repeat url(${getImage(bot.avatar, 600)})`"
        class="bot-avatar"
      >
        <div class="gradient" />
        <div class="bot-name">
          {{ bot.displayName }}<span class="bot-version"> v {{ bot.v }}</span>
        </div>
        <div class="bot-icons">
          <img
            :src="getAntUrl(bot.skin)"
            alt="Bot skin"
            class="bot-skin"
            width="40px"
            height="40px"
          >
          <img
            :src="getLangUrl(bot.lang)"
            :alt="bot.lang"
            width="40px"
          >
        </div>
        <div class="bot-info">
          <div>
            <span>Size:</span>
            <span class="info-value">{{ bot.hive }}/{{ bot.ants }}</span>
          </div>
          <div>
            <span>Score:</span>
            <span class="info-value">
              {{ getNumberTruncatedToThousand(bot.score) }}
            </span>
          </div>
          <div>
            <span>Errors:</span>
            <span class="info-value">
              {{ getNumberTruncatedToThousand(bot.errors) }} %
            </span>
          </div>
          <div>
            <span>RT:</span>
            <span class="info-value">
              {{ getArtInMs(bot.art) }} ms
            </span>
          </div>
        </div>
        <v-img
          v-if="getVsImage(index)"
          :src="getVsImage(index)"
          width="45"
          class="vs-img"
        />
      </v-list-item>
      <v-img
        v-if="getFfaImage"
        :src="getFfaImage"
        width="80"
        class="ffa-img"
      />
    </div>
    <div class="game-info">
      <v-row class="pa-3">
        <v-col class="info-row" cols="12" sm="6">
          <v-icon class="mb-n1 mr-2" color="#d1cae8">
            mdi-timer
          </v-icon>
          {{ game.age }}
        </v-col>
        <v-col class="info-row" cols="12" sm="6">
          <v-icon class="mb-n1 mr-2" color="#d1cae8">
            billiards-rack
          </v-icon>
          {{ getGameScore }}
        </v-col>
      </v-row>
      <div class="author-info">
        <p>
          By <span class="author-name">{{ getAuthorName }}</span>
        </p>
        <p>{{ getTimeAgo.time }}</p>
      </div>
      <div class="game-info-layout">
        <v-list-item
          :href="`${websiteUrl}/game/?id=${game.id}&v=${game.v}`"
          class="d-flex justify-center"
        >
          <v-icon
            color="white"
            size="50"
          >
            mdi-play-circle
          </v-icon>
        </v-list-item>
      </div>
    </div>
  </div>
</template>

<script>
import Image from '@/mixins/image'
import Time from '@/mixins/time.js'
import Truncate from '@/mixins/truncate'

export default {
  name: 'AntHiveGameVertical',
  mixins: [Image, Truncate, Time],
  props: {
    game: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data: () => ({
    hoverOnChip: false,
    hoverOnBots: false,
    websiteUrl: process.env.VUE_APP_WEBSITE_URL
  }),
  computed: {
    getTimeAgo() {
      return this.timeAgo(this.game.finished)
    },
    getFfaImage() {
      if (this.game.bots.length > 4) {
        return '/img/ffa.svg'
      }
      return null
    },
    getAuthorName() {
      return this.game.author
    },
    getGameScore() {
      return this.game.bots && this.game.bots.reduce((acc, bot) => (acc += bot.score), 0)
    }
  },
  methods: {
    getArtInMs(art) {
      return Math.round(art / 10) / 100
    },
    getVsImage(botIndex) {
      if (botIndex !== this.game.bots.length - 1 && this.game.bots.length > 1 && this.game.bots.length < 5) {
        return '/img/vs.svg'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';
$animation: ease 0.4s;
$bot-info-width: 140px;

.chip {
  width: 100%;
  max-width: 320px;
  height: 100%;
  margin: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: $box-shadow-default;
  background-color: $white;

  &:hover {
    .game-info-layout {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.author-name,
.time-ago {
  font-size: $font-medium;
  color: $violet;
  margin: 0;
  line-height: $font-big;
}

.game-info {
  padding: 8px;
  position: relative;
  .info-row {
    font-weight: $font-weight-bold;
    padding: 5px 12px;
  }
}

.game-info-layout {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: $animation;
  opacity: 0;
  pointer-events: none;
  background: $black-transparent;
}

.author-info {
  display: flex;
  justify-content: space-between;
  * {
    font-size: $font-small;
    color: $violet-light;
    margin: 0;
  }
  .author-name {
    font-weight: $font-weight-bold;
    color: $violet;
  }
}

.bot-info {
  position: absolute;
  width: $bot-info-width;
  height: 100%;
  padding: 20px;
  right: -$bot-info-width;
  opacity: 0;
  background-color: $lilac-select;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: right $animation;
  pointer-events: none;

  div {
    display: flex;
    justify-content: space-between;
    color: $violet-light;
  }

  .info-value {
    font-weight: $font-weight-bold;
  }
}

.bots {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  height: 185px;
  margin: -0.6px;

  &:hover {
    .ffa-img,
    .vs-img {
      opacity: 0;
    }

    .gradient {
      opacity: 1;
    }

    & .bot-avatar {
      min-width: 5%;
      flex: 0;
    }
  }
}
.bot-skin {
  padding: 0 5px;
}

.bot-skin,
.bot-name {
  opacity: 0;
  pointer-events: none;
  transition: $animation;
}

.bot-name {
  position: absolute;
  width: calc(100% - #{$bot-info-width} - 10px);
  left: 10px;
  top: 5px;
  color: $white;
  font-size: $font-big;
  font-weight: $font-weight-bold;
}

.bot-version {
  white-space: nowrap;
  font-weight: $font-weight-normal;
}

.bot-avatar {
  position: relative;
  cursor: pointer;
  flex: 1;
  min-width: 24%;
  margin: 0.6px;
  text-decoration: none !important;
  transition: $animation;
  &:hover {
    flex: 3 !important;
    width: 100% !important;

    .bot-skin,
    .bot-name {
      opacity: 1;
    }

    .bot-info {
      opacity: 1;
      right: 0;
    }
  }
}

.ffa-img,
.vs-img {
  transition: $animation;
  opacity: 1;
  position: absolute;
  pointer-events: none;
}

.vs-img {
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
  z-index: 1;
}

.ffa-img {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bot-icons {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  * {
    background-color: $white;
    border-right: 2px solid $white;
    border-top: 2px solid $white;
  }
}

.button {
  max-width: 145px;
  width: 100%;
  margin: 0 auto;
}

.gradient {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: $user-background-gradient;
  pointer-events: none;
  opacity: 0;
  transition: $animation;
}
</style>
