<template>
  <v-navigation-drawer
    v-model="drawer"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :mini-variant="!isOpen"
    mini-variant-width="60"
    width="220"
    class="drawer"
    app
  >
    <div
      class="drawer__list-wrap"
    >
      <div class="drawer__main-list">
        <!-- First Navigation block -->
        <v-list dense nav class="py-0">
          <div @click="handlerClickLogo">
            <TheLogo :small="!isOpen" light />
          </div>
          <v-tooltip
            v-for="(item, index) in topNavItems1"
            :key="item.name + index"
            :disabled="isOpen"
            nudge-left="25"
            color="black"
            right
          >
            <template v-slot:activator="{ on }">
              <v-list-item
                :ripple="false"
                link
                :to="item.href"
                v-on="on"
              >
                <v-list-item-icon>
                  <v-icon class="drawer__item-icon">
                    {{ item.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title :id="item.name === 'New Game' ? 'tour-step-8' : ''" class="drawer__item-title">
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <span>{{ item.name }}</span>
          </v-tooltip>
        </v-list>

        <!-- Website items for mobile -->
        <v-list
          v-if="$vuetify.breakpoint.smAndDown"
          dense
          nav
          class="py-0 mt-2"
        >
          <v-tooltip
            v-for="(item, index) in websiteItems"
            :key="item.name + index"
            :disabled="isOpen"
            nudge-left="25"
            color="black"
            right
          >
            <template v-slot:activator="{ on }">
              <v-list-item
                :ripple="false"
                link
                :href="item.href"
                v-on="on"
              >
                <v-list-item-icon>
                  <v-icon class="drawer__item-icon">
                    {{ item.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="drawer__item-title">
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <span>{{ item.name }}</span>
          </v-tooltip>
        </v-list>
      </div>

      <div class="drawer__bottom-list">
        <!-- Bottom Navigation block -->
        <v-list dense nav class="py-0">
          <v-tooltip
            v-for="(item, index) in bottomNavItems"
            :key="item.name + index"
            :disabled="isOpen"
            nudge-left="25"
            color="black"
            right
          >
            <template v-slot:activator="{ on }">
              <v-list-item
                :ripple="false"
                link
                target="_blank"
                :href="item.href"
                v-on="on"
              >
                <v-list-item-icon>
                  <v-icon class="drawer__item-icon">
                    {{ item.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="drawer__item-title">
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <span>{{ item.name }}</span>
          </v-tooltip>
        </v-list>
        <!-- Collapse button block -->
        <v-list dense nav class="py-0">
          <v-tooltip
            :disabled="isOpen"
            nudge-left="25"
            color="black"
            right
          >
            <template v-slot:activator="{ on }">
              <v-list-item :ripple="false" v-on="on" @click="isOpen = !isOpen">
                <v-list-item-icon>
                  <v-icon v-if="isOpen" class="drawer__item-icon">
                    mdi-chevron-left-circle-outline
                  </v-icon>
                  <v-icon v-else class="drawer__item-icon">
                    mdi-chevron-right-circle-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="drawer__item-title">
                    Collapse
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <span>Expand</span>
          </v-tooltip>
        </v-list>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import Images from '@/mixins/image.js'
import { mapGetters } from 'vuex'
import TheLogo from '@/components/TheLogo'

export default {
  name: 'TheDrawer',
  components: {
    TheLogo
  },
  mixins: [Images],
  props: {
    isOpenMobileDrawer: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    drawer: false,
    isOpen: true,
    websiteUrl: process.env.VUE_APP_WEBSITE_URL,
    topNavItems1: [
      { name: 'Dashboard', icon: 'mdi-home', href: '/dashboard' },
      { name: 'Career', icon: 'mdi-marker-check', href: '/career' },
      { name: 'New Game', icon: 'mdi-gamepad-variant', href: '/new-game' }
    ],
    bottomNavItems: [
      { name: 'Terms of Service', icon: 'mdi-book-open-outline', href: process.env.VUE_APP_WEBSITE_URL + '/tos' },
      { name: 'Privacy Policy', icon: 'mdi-book-open-page-variant', href: process.env.VUE_APP_WEBSITE_URL + '/pp' },
      {
        name: 'Report Issue',
        icon: 'mdi-alert-circle-outline',
        href: 'https://github.com/anthive/website/issues'
      }
    ],
    websiteItems: [
      { name: 'Games', icon: 'mdi-eye-outline', href: process.env.VUE_APP_WEBSITE_URL + '/games' },
      { name: 'Leaderboard', icon: 'mdi-book-account-outline', href: process.env.VUE_APP_WEBSITE_URL + '/leaderboard' },
      { name: 'Rules', icon: 'mdi-bookmark-multiple-outline', href: process.env.VUE_APP_WEBSITE_URL + '/rules' },
      { name: 'Sandbox', icon: 'mdi-hexagon-slice-2', href: process.env.VUE_APP_WEBSITE_URL + '/sandbox' }
    ]
  }),
  computed: {
    ...mapGetters(['getUserDB']),
    mediumBreakpoint() {
      return this.$vuetify.breakpoint.mdAndDown
    }
  },
  watch: {
    mediumBreakpoint(isMedium) {
      this.isOpen = !isMedium
    },
    isOpenMobileDrawer(state) {
      this.drawer = true
    },
    drawer(state) {
      this.$emit('update:isOpenMobileDrawer', false)
    }
  },
  methods: {
    handlerClickLogo() {
      window.location.href = this.websiteUrl
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/style/global.scss';
.drawer {
  & .v-list--nav.v-list--dense .v-list-item:not(:last-child):not(:only-child),
  .v-list--nav .v-list-item--dense:not(:last-child):not(:only-child),
  .v-list--rounded.v-list--dense .v-list-item:not(:last-child):not(:only-child),
  .v-list--rounded .v-list-item--dense:not(:last-child):not(:only-child) {
    margin-bottom: -7px;
  }
  .v-navigation-drawer--open .v-list-item--dense .v-list-item__icon,
  .v-navigation-drawer--open .v-list--dense .v-list-item .v-list-item__icon {
    height: 24px;
    margin-top: 12px;
    margin-bottom: 8px;
    margin-left: 15px;
  }
  & .v-list-item__icon {
    margin-right: 12px !important;
  }
  &.v-navigation-drawer--mini-variant .v-list-item__icon {
    margin-right: 0 !important;
  }
  & .theme--light.v-list-item--active:hover::before,
  .theme--light.v-list-item--active::before {
    display: none;
  }
  & .v-list-item--active .v-list-item__title {
    color: $color-white !important;
  }
  & .v-list-item--active .v-list-item__icon i {
    color: $color-white !important;
  }
  & .theme--light.v-list-item:hover::before {
    display: none;
  }
  & .theme--light.v-list-item:hover .v-list-item__title {
    color: $color-violet-100 !important;
    transition: 0.3s;
  }
  & .theme--light.v-list-item:hover .v-list-item__icon i {
    color: $color-violet-100 !important;
    transition: 0.3s;
  }
  & .theme--light.v-list-item--active:hover::after,
  .theme--light.v-list-item--active::after {
    opacity: 1;
    background-color: unset;
    display: block;
    content: '';
    position: absolute;
    top: 4px;
    left: -9px;
    bottom: 6px;
    width: 5px;
    background-color: rgb(108, 95, 199);
  }
}
</style>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';
.drawer {
  background: $color-violet-700 !important;
  &__logo {
    margin: 15px auto 30px auto;
    display: block;
  }
  &__logo-small {
    margin: 15px auto 30px auto;
    display: block;
  }
  &__admin-header {
    color: $color-orange-500;
    text-align: center;
  }
  &__list-wrap {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    padding: 10px 0;
  }
  &__user-name {
    font-size: 16px !important;
    color: $color-white;
    overflow: unset !important;
  }
  &__item-title {
    font-size: 15px !important;
    color: $color-white;
    font-weight: 400 !important;
    &.admin {
      color: $color-orange-500;
    }
  }
  &__item-icon {
    color: $color-white;
    font-size: 22px !important;
    &.admin {
      color: $color-orange-500;
    }
  }
  &__sub-item-title {
    font-size: 16px !important;
  }
}
</style>
