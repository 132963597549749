<template>
  <section v-if="bot" class="edit-bot">
    <v-container class="edit-container">
      <AntHiveBotVertical :bot="bot">
        <template v-slot:actions-bottom>
          <div id="tour-step-5" class="d-flex align-center justify-start my-1">
            <v-switch
              v-model="bot.isActive"
              class="mr-2"
              color="primary"
              @change="updateCurrentBot"
            />
            <AntHiveButton
              class="mr-2"
              icon
              :loading="loading"
              color="primary"
              @click="isEditBotMode = true"
            >
              <v-icon>
                mdi-pencil
              </v-icon>
            </AntHiveButton>
            <AntHiveButton
              v-if="!bot.isActive"
              class="mr-2"
              icon
              :loading="loading"
              color="primary"
              @click="deleteCurrentBot"
            >
              <v-icon>
                mdi-trash-can-outline
              </v-icon>
            </AntHiveButton>
          </div>
        </template>
      </AntHiveBotVertical>

      <div class="ma-4">
        <h3 v-if="bot.provider !== 'sandbox'" class="pb-4">
          {{ bot.provider }}.com/{{ bot.owner }}/{{ bot.name }}
        </h3>
        <div v-show="editor" id="tour-step-6" class="mb-5">
          <div class="editor-toolbar">
            <div class="editor-title">
              {{ selectedCommitName }}
            </div>
            <AntHiveButton
              :loading="loading"
              dark
              color="#1DD07A"
              @click="runCodeGame"
            >
              Validate code
            </AntHiveButton>
          </div>
          <v-card
            tile
            width="100%"
            height="400"
            color="#272822"
          >
            <div :id="editorLang && editorLang.editor" class="editor" />
          </v-card>
        </div>
        <div v-if="bot && commits" id="tour-step-7" class="commits">
          <v-card
            v-for="commit in commits"
            :key="commit.sha + commit.v"
            :disabled="commit.status === 'new'"
            tile
            :class="{ 'commit--active': bot.v == commit.v }"
            class="commit"
            @click="commit.status === 'passed' ? selectBotVersion(commit): showBotLogs(commit.sha)"
          >
            <div class="commit-content">
              <div>
                <div v-if="commit.status === 'passed'" class="radio-wrap">
                  <div v-if="commit.v === bot.v" icon>
                    <v-icon class="commit-checkbox">
                      mdi-checkbox-intermediate
                    </v-icon>
                  </div>
                  <div v-else class="radio-button">
                    <v-icon class="commit-checkbox">
                      mdi-checkbox-blank-outline
                    </v-icon>
                  </div>
                </div>
                <AntHiveButton
                  v-if="commit.status === 'failed'"
                  color="red"
                  class="pr-2 mr-2"
                >
                  Logs
                </AntHiveButton>
                <v-progress-circular
                  v-if="commit.status === 'new'"
                  class="commit-checkbox"
                  :size="30"
                  color="primary"
                  indeterminate
                />
              </div>
              <div class="commit-name">
                <div class="commit-message">
                  {{ commit.message }}
                </div>
                <div class="f-text-small">
                  <a class="commit-sha">{{ commit.sha }}</a>
                </div>
              </div>
              <div class="commit-stat">
                Version: <span class="commit-stat-value">{{ commit.v }}</span>
              </div>
              <div class="commit-stat">
                Games: <span class="commit-stat-value">{{ commit.games }}</span>
              </div>
              <div class="commit-stat">
                Errors: <span class="commit-stat-value">{{ commit.errors }}%</span>
              </div>
              <div class="commit-stat">
                ART: <span class="commit-stat-value">{{ commit.art }}</span>
              </div>
              <div class="commit-stat">
                W/L: <span class="commit-stat-value">{{ commit.wins }}/{{ commit.losses }}</span>
              </div>
            </div>
          </v-card>
        </div>
      </div>

      <v-dialog
        v-model="isShowLogs"
        :fullscreen="$vuetify.breakpoint.mdAndDown"
        tile
      >
        <v-card
          class="dialog"
          min-height="215"
        >
          <v-icon class="close-dialog" @click="isShowLogs=false">
            mdi-close
          </v-icon>
          <pre>{{ commitLogs }}</pre>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="isCanAddCommit"
        :fullscreen="$vuetify.breakpoint.mdAndDown"
        tile
        max-width="1024"
      >
        <v-card
          v-if="isCanAddCommit"
          class="dialog"
          min-height="215"
        >
          <v-icon class="close-dialog" @click="isCanAddCommit=false">
            mdi-close
          </v-icon>
          <h3>Bot version successfully created</h3>
          <div class="d-flex flex-wrap">
            <v-col cols="12" md="8">
              <AntHiveInput
                :error-message="commitMessageValidationsError"
                :error="$v.message.$invalid"
                :value.sync="message"
              />
            </v-col>
            <v-col cols="12" md="4">
              <AntHiveButton
                :disabled="$v.message.$invalid"
                color="primary"
                @click="addVersion"
              >
                Add to versions
              </AntHiveButton>
            </v-col>
          </div>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="isEditBotMode"
        :fullscreen="$vuetify.breakpoint.mdAndDown"
        tile
        max-width="1040"
        width="100%"
        class="col-12"
      >
        <v-card v-if="isEditBotMode" class="dialog">
          <v-icon class="close-dialog" @click="isEditBotMode=false">
            mdi-close
          </v-icon>
          <h3>Change your bot display name and avatar</h3>
          <div class="d-flex flex-wrap align-center">
            <v-col cols="12" md="5">
              <v-img
                v-if="bot"
                width="100"
                :src="getImage(bot.avatar, 400)"
                :alt="bot.avatar"
              >
                <div class="edit-button edit-avatar" @click="openChangeBotAvatarDialog">
                  <v-icon>mdi-pencil</v-icon>
                </div>
                <input
                  ref="botAvatar"
                  type="file"
                  name="botAvatar"
                  accept="image/*"
                  style="display: none;"
                  @change="changeBotAvatar"
                >
              </v-img>
            </v-col>
            <v-col cols="12" md="8">
              <AntHiveInput
                :error-message="botNewDisplayNameValidationsError"
                :error="$v.botNewDisplayName.$invalid"
                :value.sync="botNewDisplayName"
              />
            </v-col>

            <v-col cols="12" md="2">
              <AntHiveButton
                :disabled="$v.botNewDisplayName.$invalid"
                color="primary"
                @click="changeBotName"
              >
                Save
              </AntHiveButton>
            </v-col>
          </div>

          <h3>Change skin of your hive</h3>

          <div class="skins">
            <v-card
              v-for="skin in skins"
              :key="skin.id"
              :disabled="!skin.opened"
              class="skin"
              @click="changeBotSkin(skin)"
            >
              <v-img
                width="150"
                height="150"
                class="hive-skin"
                :src="skin.hiveBig"
              />

              <v-icon v-if="skin.opened && bot.skin === skin.id" class="skin-radio">
                mdi-radiobox-marked
              </v-icon>

              <v-icon v-if="skin.opened && bot.skin !== skin.id" class="skin-radio">
                mdi-radiobox-blank
              </v-icon>

              <div class="ant-skin-wrap">
                <img class="ant-skin" :src="skin.antBig">
              </div>
            </v-card>
          </div>
        </v-card>
      </v-dialog>
    </v-container>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import Images from '@/mixins/image.js'

import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'

import md5 from 'md5'
import ace from 'ace-builds'
import 'ace-builds/src-min-noconflict/theme-monokai'
import 'ace-builds/src-min-noconflict/ext-language_tools'
import 'ace-builds/webpack-resolver'
import AntHiveBotVertical from '@/components/AntHiveBotVertical'
import api from '@/api'

export default {
  name: 'EditBot',
  components: {
    AntHiveBotVertical
  },
  validations() {
    return {
      botNewDisplayName: {
        required,
        maxLength: maxLength(this.botNewDisplayNameMaxLength)
      },
      message: {
        maxLength: maxLength(this.commitMessageMaxLength)
      }
    }
  },
  mixins: [Images, validationMixin],
  data() {
    return {
      loading: false,
      bot: null,
      langs: [],
      skins: [],
      editorLang: null,
      editor: null,
      editors: {},
      commits: null,
      isEditBotMode: false,
      isCanAddCommit: false,
      isShowLogs: false,
      commitLogs: '',
      sha: '',
      message: '',
      gameInQueue: false,
      selectedCommitName: null,
      botNewDisplayName: '',
      selectedSkin: null,
      commitMessageMaxLength: 20,
      botNewDisplayNameMaxLength: 12,
      sandboxRef: null,
      gameAwaitingTimeout: 180000
    }
  },
  computed: {
    botNewDisplayNameValidationsError() {
      if (!this.$v.botNewDisplayName.required) {
        return 'Display name is required field'
      }

      if (!this.$v.botNewDisplayName.maxLength) {
        return `Display name must be up to ${this.botNewDisplayNameMaxLength} characters`
      }

      return ''
    },
    commitMessageValidationsError() {
      if (!this.$v.message.maxLength) {
        return `Commit message must be up to ${this.commitMessageMaxLength} characters`
      }

      return ''
    }
  },
  watch: {
    isEditBotMode(val) {
      if (!val) {
        this.botNewDisplayName = this.bot.displayName
      }
    },
    isCanAddCommit(val) {
      if (!val) {
        this.message = ''
      }
    }
  },
  async mounted() {
    this.loading = true
    this.langs = await api.bots.getLangs()
    this.skins = await api.users.getUserSkins()
    this.bot = await api.bots.getBot(this.$route.params.id)
    this.commits = await api.bots.getCommits(this.bot.id)

    if (this.bot.provider === 'sandbox') {
      this.editorLang = this.langs.find(lang => lang.id === this.bot.lang)
      this.initEditor(this.editorLang.editor)
    }

    this.selectedSkin = this.skins.find(skin => skin.id === this.bot.skin)
    this.botNewDisplayName = this.bot.displayName
    const currentCommit = this.commits.find(commit => commit.v === this.bot.v)
    this.selectBotVersion(currentCommit)

    if (this.$tours['tour'].currentStep === 4) this.$tours['tour'].nextStep()
    this.loading = false
  },
  destroyed() {
    if (this.sandboxRef) {
      this.sandboxRef.off()
    }
  },
  methods: {
    ...mapActions(['setErrorAlert']),
    async showBotLogs(sha) {
      const logs = await api.games.getGameLogs(sha)
      if (logs) {
        this.isShowLogs = true
        this.commitLogs = logs
      }
    },
    async changeBotAvatar(event) {
      this.filesImgAvatar = event.target.files || event.dataTransfer.files
      if (!this.filesImgAvatar.length) return

      const imageId = await api.users.saveImage(this.filesImgAvatar[0])
      if (!imageId) return

      this.bot.avatar = imageId
      await this.updateCurrentBot()
    },
    openChangeBotAvatarDialog() {
      this.$refs.botAvatar.click()
    },
    async changeBotName() {
      this.bot.displayName = this.botNewDisplayName
      await this.updateCurrentBot()
    },
    async changeBotSkin(skin) {
      this.bot.skin = skin.id
      this.selectedSkin = skin
      await this.updateCurrentBot()
    },
    async updateCurrentBot() {
      this.loading = true
      await api.bots.updateBot(this.bot)
      this.loading = false
    },
    async deleteCurrentBot() {
      this.loading = true
      await api.bots.deleteBot(this.bot.id)
      this.loading = false
      this.$router.push({ name: 'Dashboard' })
    },
    async runCodeGame() {
      this.loading = true
      const lang = this.editorLang.extention
      const code = this.editor.getValue()
      const sha = md5(code)

      const existedCommit = this.commits.find(commit => commit.sha === sha)

      if (existedCommit) {
        this.selectBotVersion(existedCommit)
        this.loading = false
        return
      }

      const isGameExist = await api.games.checkGameExistence(sha)
      if (isGameExist) {
        this.loading = false
        this.isCanAddCommit = true
        this.sha = sha
        return
      }

      await api.games.runGame(lang, code, sha)

      const awaitingTimeout = setTimeout(this.abortWaitingForGame, this.gameAwaitingTimeout)
      this.sandboxRef = this.$firebase.database().ref('sandbox')
      this.sandboxRef.on('value', async(snapshot) => {
        const games = snapshot.val() ? Object.values(snapshot.val()) : []
        const hasGame = games.some(game => game.sum === sha)
        if (this.gameInQueue && !hasGame) {
          clearTimeout(awaitingTimeout)
          this.loading = false
          this.sandboxRef.off()

          const isGameExist = await api.games.checkGameExistence(sha)
          if (!isGameExist) {
            this.abortWaitingForGame()
            return
          }

          this.isCanAddCommit = true
          this.sha = sha
          return
        }
        this.gameInQueue = hasGame
      })
    },
    abortWaitingForGame() {
      this.loading = false
      this.sandboxRef.off()
      this.setErrorAlert('Oops something went wrong please try again later or contact us in Discord')
    },
    async selectBotVersion(commit) {
      const currentVersion = this.bot.v

      this.bot.v = commit.v
      this.bot.games = commit.games
      this.bot.errors = commit.errors
      this.bot.art = commit.art
      this.bot.wins = commit.wins
      this.bot.losses = commit.losses
      this.selectedCommitName = commit.message

      if (currentVersion !== commit.v) {
        await this.updateCurrentBot()
      }

      if (this.bot.provider === 'sandbox') {
        const data = await api.games.getGameCode(commit.sha, this.editorLang.extention)
        this.editor.setValue(data)
        this.editor.clearSelection()
      }
    },
    async initEditor(lang) {
      new Promise(async resolve => {
        await require(`ace-builds/src-min-noconflict/mode-${lang}`)
        await require(`ace-builds/src-min-noconflict/snippets/${lang}`)
        return resolve()
      }).then(async() => {
        this.editor = ace.edit(lang, {
          theme: 'ace/theme/monokai',
          mode: `ace/mode/${lang}`,
          fontSize: 14,
          printMargin: false,
          autoScrollEditorIntoView: true,
          enableBasicAutocompletion: true,
          enableSnippets: true,
          enableLiveAutocompletion: true
        })

        const currentCommit = this.commits.find(commit => commit.v === this.bot.v)
        this.selectBotVersion(currentCommit)

        this.editors[lang] = this.editor
      })
    },
    async addVersion() {
      this.loading = true

      await api.bots.addSandboxCommit(this.bot.id, this.message, this.sha)
      this.commits = await api.bots.getCommits(this.bot.id)
      this.loading = false
      this.isCanAddCommit = false
      this.message = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.edit-bot {
  background-color: $color-grey-50;
  height: 100%;
}

.flow-switch {
  margin-bottom: 35px;
}

.edit-container {
  display: flex;
  flex-wrap: wrap;
}

.commits {
  max-height: 445px;
  overflow-y: auto;
}

.commit {
  margin-bottom: 10px;
  .commit-content {
    color: $color-violet-350;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 14px 28px;
  }

  .commit-name {
    margin-right: -40px;
  }

  .commit-message {
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    font-size: $font-big;
    color: $color-violet-700;
    font-weight: $font-weight-bold;
  }

  .commit-sha {
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    font-size: $font-medium;
  }

  .commit-stat {
    color: $color-violet-700;
    min-width: 35px;
    margin-left: 58px;
    display: flex;
  }

  .commit-stat-value {
    margin-left: 5px;
    font-weight: $font-weight-bold;
  }

  .commit-checkbox {
    color: $color-violet-350;
    margin-right: 40px;
  }
}

.editor {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.editor-toolbar {
  background-color: $color-violet-700;
  display: flex;
  justify-content: space-between;
  padding: 5px;

  .editor-title {
    color: $color-white;
    font-weight: $font-weight-bold;
    padding-left: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.dialog {
  padding: 50px 120px;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 100%;

  @media screen and (max-width: $screen-md) {
   padding: 50px 0 !important;
  }

  h3 {
    margin-bottom: 30px;
  }

  .close-dialog {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: $font-bigger;
  }
}

.skins {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -30px;
}

.edit-button {
  cursor: pointer;
  width: 35px;
  height: 35px;
  background-color: $color-white;
  border-radius: $border-radius-default;

  i {
    width: 100%;
    height: 100%;
    color: $color-violet-450;
  }

  &:hover i{
    color: $violet-light;
  }
}

.edit-avatar {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.skin {
  cursor: pointer;
  width: 150px;
  height: 150px;
  position: relative;
  margin: 0 30px 78px 30px;

  @media screen and (max-width: $screen-md) {
   margin: 10px auto;
  }

  .ant-skin-wrap {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50px;
    bottom: -8px;
    width: 58px;
    height: 58px;
    background: white;
    box-shadow: 0px 0px 7px rgb(0 0 0 / 25%);
  }

  .skin-radio {
    top: 0;
    position: absolute;
    color: $color-violet-700;
    background: white;
    right: 0;
  }

  .ant-skin {
    height: 48px;
  }
}
</style>
