<template>
  <v-card class="bot-card" width="200">
    <v-icon v-if="index !== -1" class="bot-card__remove" @click="removeBot">
      mdi-close
    </v-icon>
    <div
      class="bot-card__img"
      :style="
        `background-image: url(${getImage(bot.avatar, 300)});background-size: cover;background-position: center;`
      "
    />
    <v-avatar class="bot-card__lang" size="35">
      <v-img :src="getLangUrl(bot.lang)" />
    </v-avatar>
    <div class="bot-card__container">
      <!-- Bot settings -->
      <div class="bot-card__info">
        <div>
          <span class="bot-card__info-name">{{ bot.displayName }}</span>
          <span class="bot-card__info-lang">{{ bot.lang }}</span>
        </div>
      </div>

      <div class="bot-card__stats">
        <div>
          <div class="bot-card__stat">
            Games: <span class="bot-card__stat-value">0</span>
          </div>
          <div class="bot-card__stat">
            MMR : <span class="bot-card__stat-value">{{ bot.mmr }}</span>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import Images from '@/mixins/image.js'

export default {
  name: 'NewGameBotSelect',
  mixins: [Images],
  props: {
    bot: { type: Object, required: true },
    index: {
      type: Number,
      required: false,
      default: -1
    }
  },
  methods: {
    removeBot() {
      this.$emit('onBotRemove', this.index)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/style/global.scss';
.bot-card {
  position: relative;
  border-radius: $border-radius-tile !important;
  width: 190px;
  max-height: 270px;
  &__remove {
    top: 5px;
    right: 5px;
    position: absolute;
  }
  &__img {
    height: 130px;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  &__container {
    padding: 5px 10px;
  }
  &__avatar {
    border-radius: $border-radius-tile !important;
    display: block;
    margin: -35px auto 0 auto;
    box-sizing: content-box;
    background: $color-red-300;
  }
  &__lang {
    border-radius: $border-radius-tile !important;
    top: 13px;
    left: 13px;
    position: absolute;
  }
  &__info {
    &-name {
      display: block;
      margin-top: 5px;
      font-weight: 600;
      color: $color-violet-700 !important;
    }
    &-lang {
      display: block;
      margin-top: -5px;
      font-size: $font-small;
      color: $color-violet-500 !important;
      text-transform: capitalize;
    }
  }
  &__stats {
    margin: 15px 0;
  }
  &__stat {
    font-size: $font-small;
    color: $color-violet-700;
    &-value {
      color: $color-violet-700;
      font-weight: 600;
    }
  }
}
</style>
