import { externalApi } from '../plugins/axios'

export const getSteps = async() => {
  try {
    const { data } = await externalApi.get('/stepCareer/stepCareer100.json')
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}
