<template>
  <!-- eslint-disable max-len  -->
  <svg
    v-if="small"
    class="small-ant"
    viewBox="0 0 22 47"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;"
  >
    <g transform="matrix(1,0,0,1,-6736,-32)">
      <g transform="matrix(1,0,0,1.62195,6602.64,0.128014)">
        <g transform="matrix(0.293851,0,0,0.385963,-1094.46,-2669.03)">
          <rect
            x="4212.66"
            y="7000.61"
            width="12.145"
            height="39.783"
            style="fill:url(#_Linear1);"
          />
        </g>
        <g transform="matrix(0.27048,0,0,0.527874,100.192,-379.532)">
          <g>
            <g transform="matrix(0.898692,0,0,0.295634,-3633.83,162.953)">
              <path d="M4234,2063.14L4254,2046L4253.5,2016.98" style="fill:none;stroke:white;stroke-width:6.06px;" />
            </g>
            <g transform="matrix(-0.898692,0,0,0.295634,3960.31,162.697)">
              <path d="M4234,2063.14L4254,2046L4253.5,2016.98" style="fill:none;stroke:white;stroke-width:6.06px;" />
            </g>
            <g transform="matrix(0.898692,0,0,0.295634,-3633.83,162.658)">
              <path d="M4234,2063.28L4253.5,2082.78L4253.5,2129.96" style="fill:none;stroke:white;stroke-width:6.06px;" />
            </g>
            <g transform="matrix(-0.898692,0,0,0.295634,3959.86,162.658)">
              <path d="M4234,2063.28L4253.5,2082.78L4253.5,2129.96" style="fill:none;stroke:white;stroke-width:6.06px;" />
            </g>
            <g transform="matrix(0.898692,0,0,0.295634,-3632.1,160.884)">
              <path d="M4232.08,2070.14L4252.08,2070.14" style="fill:none;stroke:white;stroke-width:6.06px;" />
            </g>
            <g transform="matrix(-0.898692,0,0,0.295634,3958.14,160.627)">
              <path d="M4232.08,2070.14L4252.08,2070.14" style="fill:none;stroke:white;stroke-width:6.06px;" />
            </g>
            <g transform="matrix(0.898692,0,0,0.295634,-3632.1,160.884)">
              <path d="M4252.08,2070.14L4262.06,2080.13" style="fill:none;stroke:white;stroke-width:6.06px;" />
            </g>
            <g transform="matrix(-0.898692,0,0,0.295634,3958.14,160.627)">
              <path d="M4252.08,2070.14L4262.06,2080.13" style="fill:none;stroke:white;stroke-width:6.06px;" />
            </g>
            <g transform="matrix(0.898692,0,0,0.295634,-3632.1,161.475)">
              <path d="M4229,2028L4237,2022L4239.02,2016.18" style="fill:none;stroke:white;stroke-width:6.06px;" />
            </g>
            <g transform="matrix(-0.898692,0,0,0.295634,3959.86,161.475)">
              <path d="M4229,2028L4237,2022L4239.06,2016.18" style="fill:none;stroke:white;stroke-width:6.06px;" />
            </g>
          </g>
          <g transform="matrix(2.66705,0,0,0.842558,-9351.15,712.327)">
            <path d="M3565.63,76.269C3564.91,76.105 3564.37,75.458 3564.37,74.687L3564.37,68.46C3564.37,67.564 3565.09,66.837 3565.99,66.837L3569.24,66.837C3570.13,66.837 3570.86,67.564 3570.86,68.46L3570.86,74.687C3570.86,75.458 3570.32,76.105 3569.6,76.269C3571.57,77.059 3572.97,78.991 3572.97,81.248C3572.97,85.575 3567.61,95.672 3567.61,95.672C3567.61,95.672 3562.25,85.575 3562.25,81.248C3562.25,78.991 3563.65,77.059 3565.63,76.269ZM3572.24,59.326C3572.24,57.313 3570.61,55.679 3568.59,55.679L3566.63,55.679C3564.62,55.679 3562.98,57.313 3562.98,59.326L3562.98,62.207C3562.98,64.22 3564.62,65.854 3566.63,65.854L3568.59,65.854C3570.61,65.854 3572.24,64.22 3572.24,62.207L3572.24,59.326Z" style="fill:white;" />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <linearGradient
        id="_Linear1"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(4.0584e-15,-31.1113,66.2787,1.90502e-15,4218.73,7040.4)"
      ><stop offset="0" style="stop-color:white;stop-opacity:0.12" /><stop offset="1" style="stop-color:rgb(135,79,211);stop-opacity:1" /></linearGradient>
    </defs>
  </svg>
  <svg
    v-else
    class="logo"
    viewBox="0 0 144 47"
    xmlns="http://www.w3.org/2000/svg"
    fill-rule="evenodd"
    height="100%"
    width="300px"
    clip-rule="evenodd"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-miterlimit="1.5"
  >
    <g class="ant">
      <path
        fill="url(#_Linear1)"
        d="M4212.66 7000.61h12.145v39.783h-12.145z"
        transform="matrix(.29316 0 0 .62455 -1141.144 -4350.458)"
      />
      <path
        :fill="getColor"
        d="M98.95 5.54a2.645 2.645 0 00-2.642-2.644h-1.426A2.645 2.645 0 0092.24 5.54v2.086a2.645 2.645 0 002.642 2.645h1.426a2.645 2.645 0 002.642-2.645V5.54zM97.949 12.16c0-.65-.528-1.176-1.177-1.176H94.42c-.649 0-1.176.526-1.176 1.177v4.512c0 .65.527 1.177 1.176 1.177h2.353c.65 0 1.177-.527 1.177-1.177V12.16z"
      />
      <path
        :fill="getColor"
        d="M95.595 31.885s3.884-7.318 3.884-10.455a3.887 3.887 0 00-3.884-3.884 3.887 3.887 0 00-3.884 3.884c0 3.137 3.884 10.455 3.884 10.455z"
      />
      <path
        :stroke="getColor"
        d="M4234 2063.14l20-17.14-.5-29.02"
        fill="none"
        stroke-width="6.06"
        transform="matrix(.24422 0 0 .2543 -936.419 -510.026)"
      />
      <path
        :stroke="getColor"
        d="M4234 2063.14l20-17.14-.5-29.02"
        fill="none"
        stroke-width="6.06"
        transform="matrix(-.24422 0 0 .2543 1127.274 -510.247)"
      />
      <path
        :stroke="getColor"
        d="M4234 2063.28l19.5 19.5v47.18"
        fill="none"
        stroke-width="6.06"
        transform="matrix(.24422 0 0 .2543 -936.419 -510.28)"
      />
      <path
        :stroke="getColor"
        d="M4234 2063.28l19.5 19.5v47.18"
        fill="none"
        stroke-width="6.06"
        transform="matrix(-.24422 0 0 .2543 1127.152 -510.28)"
      />
      <path
        :stroke="getColor"
        d="M4232.08 2070.14h20"
        fill="none"
        stroke-width="6.06"
        transform="matrix(.24422 0 0 .2543 -935.949 -511.806)"
      />
      <path
        :stroke="getColor"
        d="M4232.08 2070.14h20"
        fill="none"
        stroke-width="6.06"
        transform="matrix(-.24422 0 0 .2543 1126.685 -512.027)"
      />
      <path
        :stroke="getColor"
        d="M4252.08 2070.14l9.98 9.99"
        fill="none"
        stroke-width="6.06"
        transform="matrix(.24422 0 0 .2543 -935.949 -511.806)"
      />
      <path
        :stroke="getColor"
        d="M4252.08 2070.14l9.98 9.99"
        fill="none"
        stroke-width="6.06"
        transform="matrix(-.24422 0 0 .2543 1126.685 -512.027)"
      />
      <path
        :stroke="getColor"
        d="M4229 2028l8-6 2.02-5.82"
        fill="none"
        stroke-width="6.06"
        transform="matrix(.24422 0 0 .2543 -935.949 -511.298)"
      />
      <path
        :stroke="getColor"
        d="M4229 2028l8-6 2.06-5.82"
        fill="none"
        stroke-width="6.06"
        transform="matrix(-.24422 0 0 .2543 1127.152 -511.298)"
      />
    </g>
    <g>
      <path
        :fill="getColor"
        d="M14.061 24.016H8.198l-1.06 4.415H.64L7.139 8.299h8.3l6.216 20.132h-6.534l-1.06-4.415zm-2.966-11.373L9.01 20.095h4.203l-1.978-7.452h-.141z"
        fill-rule="nonzero"
      />
    </g>
    <g>
      <path
        :fill="getColor"
        d="M29.32 20.307v8.124H23.42V8.299h5.969l5.333 8.123a31.572 31.572 0 011.837 3.038h.106V8.299h5.863V28.43h-6.005l-5.474-8.477-1.59-2.402h-.105l-.036 2.755z"
        fill-rule="nonzero"
      />
    </g>
    <g>
      <path
        :fill="getColor"
        d="M56.233 28.43h-6.358V12.715H44.19V8.299h17.73v4.415h-5.686V28.43z"
        fill-rule="nonzero"
      />
    </g>
    <g>
      <path
        :fill="getColor"
        d="M64.003 8.299h6.392v7.382h5.475V8.299h6.393V28.43H75.87v-8.689h-5.475v8.689h-6.392V8.299z"
        fill-rule="nonzero"
      />
    </g>
    <g>
      <path
        :fill="getColor"
        d="M120.348 8.299h6.71L121.62 28.43H112.4l-5.474-20.132h6.746l3.249 15.752h.177L120.348 8.3z"
        fill-rule="nonzero"
      />
    </g>
    <g>
      <path
        :fill="getColor"
        d="M128.648 8.299h14.657v4.238h-8.3v3.673H142v3.815h-6.994v4.097h8.265v4.309h-14.622V8.299z"
        fill-rule="nonzero"
      />
    </g>
    <defs>
      <linearGradient
        id="_Linear1"
        x1="0"
        y1="0"
        x2="1"
        y2="0"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0 -31.1113 66.2787 0 4218.73 7040.4)"
      >
        <stop offset="0" stop-color="#fff" stop-opacity=".12" />
        <stop :stop-color="getColor" offset="1" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'TheLogo',
  props: {
    light: {
      type: Boolean,
      required: false,
      default: false
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    getColor() {
      return this.light ? '#fff' : '#4c377f'
    }
  }
}
</script>

  <style lang="scss" scoped>
    .logo {
      cursor: pointer;
      display: block;
      margin: 0 auto;
      width: 130px;
      height: 65px;
      &:hover {
        .ant {
          animation: 1.5s ease-out 0s infinite antmove;
        }
      }
    }
    .small-ant {
      cursor: pointer;
      display: block;
      margin: 12px auto 11px;
      width: auto;
      height: 42px;
      &:hover {
        animation: 1.5s ease-out 0s infinite antmove;
      }
    }

    @keyframes antmove {
      0%, 30%, 70%, 100% { transform: translateY(0) translateX(0) }
      50% { transform: translateY(-120px) }
      51% { transform: translateY(-120px) translateX(320px) }
      52% { transform: translateY(120px) translateX(320px) }
      53% { transform: translateY(120px) translateX(0) }
    }
  </style>
