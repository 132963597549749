import { externalApi } from '../plugins/axios'
import store from '../store/index'
import { APP } from './ProvidersConstants'

/**
 * Provides functionality of OAuth2 authorization
 */
class OAuthProvider {
  /**
   * Ctor
   * @param {Object} gitProvider Git provider GIT_PROVIDERS (common/constants)
   * @param {Object} appGitProviderAPI Application API node for selected git provider APP (common/constants)
   * @param {String} clientId Client id from OAuth app settings of provider website
   */
  constructor(gitProvider, appGitProviderAPI, clientId, scope) {
    this.gitProvider = gitProvider
    this.appGitProviderAPI = appGitProviderAPI
    this.clientId = clientId
    this.scope = scope
  }
  /**
   * Returns has OAuthProvider an access token or not
   * @returns {Boolean} Has OAuthProvider token or not
   */
  isAuthenticated() {
    return store.getters['getAccessToken'](this.gitProvider.name) !== null
  }
  /**
   * Redirects to authorization url
   */
  authorize() {
    let url = new URL(`${this.gitProvider.website}/${this.gitProvider.api.urls.oAuthAuthorize}`)
    url.searchParams.set('client_id', this.clientId)
    url.searchParams.set('redirect_uri', `${APP.api.urls.redirectUrl}${this.gitProvider.name}`)
    url.searchParams.set('scope', this.scope)
    url.searchParams.set('response_type', 'code')

    window.location.replace(url)
  }
  /**
   * Loads access token and saves it
   * @param {String} code Authorization code
   */
  async loadAccessToken(code) {
    const url = `${APP.api.urls.root}/${this.appGitProviderAPI.oAuthGetAccessToken}`
    const response = await externalApi.post(url, {
      code,
      grant_type: 'authorization_code',
      redirect_uri: `${window.location.href.replace(window.location.search, '')}`
    }).catch((error) => {
      if (error.response && error.response.status === 401) {
        this.authorize()
      }
    })

    store.dispatch(
      'setAccessToken',
      { provider: this.gitProvider.name, token: response.data['access_token'] }
    )
  }
  /**
   * Returns an access token
   * @returns {String} Access token
   */
  getAccessToken() {
    return store.getters['getAccessToken'](this.gitProvider.name)
  }
}

export default OAuthProvider
