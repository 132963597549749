<template>
  <v-container>
    <div v-if="games">
      <div class="games">
        <AntHiveGameVertical
          v-for="(game, index) in games"
          :key="index + 'game'"
          :game="game"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import AntHiveGameVertical from '@/components/AntHiveGameVertical'
import api from '@/api'

export default {
  name: 'GamesTable',
  components: {
    AntHiveGameVertical
  },
  data: () => ({
    games: []
  }),
  computed: {
    ...mapGetters(['getUserDB'])
  },
  async mounted() {
    await this.loadGames()
  },
  methods: {
    async loadGames() {
      this.games = await api.games.getUserGames(this.getUserDB.username)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.games {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}
</style>
