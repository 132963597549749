import Vue from 'vue'
import App from './App.vue'
import firebase from './plugins/firebase'
import router from './router'
import store from './store'
import VueTheMask from 'vue-the-mask'
import VueCookie from 'vue-cookie'
import Vuelidate from 'vuelidate'
import vuetify from './plugins/vuetify'
import VueTour from 'vue-tour'
import './plugins/anthive'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'

Vue.config.productionTip = false
Vue.prototype.$firebase = firebase
require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)
Vue.use(VueTheMask)
Vue.use(Vuelidate)
Vue.use(VueCookie)

Sentry.init({
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [new VueIntegration({ Vue, attachProps: true, logErrors: true })]
})

Object.defineProperty(Vue.prototype, '$user', {
  get() {
    return this.$root.user
  },
  set(user) {
    this.$root.user = user
  }
})
new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
