import { privateAnthiveApi, externalApi } from '../plugins/axios'
import store from '../store'

const BOT_HANDLER = id => `/user/bots/${id}`
const COMMITS_HANDLER = id => `/user/bots/${id}/commits`

export const getCommits = async(botId) => {
  try {
    const { data } = await privateAnthiveApi.get(COMMITS_HANDLER(botId))
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const addSandboxCommit = async(botId, message, sha) => {
  try {
    const url = `${BOT_HANDLER(botId)}/sandbox/${sha}`
    const { data } = await privateAnthiveApi.post(url, null, { params: { msg: message } })
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const updateBot = async(bot) => {
  try {
    await privateAnthiveApi.post(`${BOT_HANDLER(bot.id)}`, bot)
    store.dispatch('setSuccessAlert', 'Bot successfully updated')
    return true
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const deleteBot = async(botId) => {
  try {
    await privateAnthiveApi.post(`${BOT_HANDLER(botId)}/delete`)
    store.dispatch('setSuccessAlert', 'Bot successfully deleted')
    return true
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const getBot = async(botId) => {
  try {
    const { data } = await privateAnthiveApi.get(BOT_HANDLER(botId))
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const addBot = async(payload) => {
  try {
    const { data } = await privateAnthiveApi.post('/user/bots', payload)
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const getUserBots = async() => {
  try {
    const { data } = await privateAnthiveApi.get('/user/bots')
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const getOpponents = async() => {
  try {
    const { data } = await privateAnthiveApi.get('/user/opponents')
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const getAllBots = async() => {
  try {
    const { data } = await privateAnthiveApi.get('/admin/bots')
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const getLangs = async() => {
  try {
    const langsUrl = `${process.env.VUE_APP_WEBSITE_URL}/data/langs.json`
    const { data } = await externalApi.get(langsUrl)
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const getBotLogs = async(gameId, y, x) => {
  try {
    const logsUrl = `${process.env.VUE_APP_LOGS_BUCKET}${gameId}-s${y}-${x}.txt`
    const { data } = await externalApi.get(logsUrl)
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}
