import Fire from './plugins/firebase'

import Vue from 'vue'
import VueRouter from 'vue-router'
import SignIn from './views/SignIn.vue'
import Dashboard from './views/Dashboard.vue'
import Career from './views/Career.vue'
import NewGame from './views/NewGame.vue'
import EditBot from './views/EditBot.vue'
import AdminUsers from './views/AdminUsers.vue'
import PasswordReset from './views/PasswordReset.vue'
import VueGtag from 'vue-gtag'
import store from './store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Root'
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: SignIn
  },
  {
    path: '/reset-password',
    name: 'PasswordReset',
    component: PasswordReset
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { title: 'Dashboard' }
  },
  {
    path: '/new-game',
    name: 'New Game',
    component: NewGame,
    meta: { title: 'New Game' }
  },
  {
    path: '/edit-bot/:id',
    name: 'EditBot',
    component: EditBot,
    meta: { title: 'Edit Bot' }
  },
  {
    path: '/career',
    name: 'Career',
    component: Career,
    meta: { title: 'Career' }
  },
  {
    path: '/admin-users',
    name: 'AdminUsers',
    component: AdminUsers,
    meta: { requiresAdminAccess: true, title: 'Users' }
  },
  {
    path: '*',
    redirect: { name: 'Dashboard' }
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

Vue.use(VueGtag, {
  config: { id: 'G-9W6WDVE5DT' }
}, router)

let currentUser
router.beforeEach(async(to, from, next) => {
  if (!currentUser) {
    currentUser = await Fire.getCurrentUser()
  }
  store.dispatch('clearAlert')

  // if user not loggened
  if (to.meta.requiresAdminAccess) {
    const adminsUid = process.env.VUE_APP_ADMINS_UID
    // if user is admin
    if (adminsUid && adminsUid.includes(currentUser.uid)) next()
    else next({ name: 'Dashboard' })
    // open user profile as admin
  } else if (!to.query.loginAs && from.query.loginAs && from.query.loginAs.length === 28) {
    next({ name: to.name, params: to.params, query: { loginAs: from.query.loginAs } })
  } else if (to.name === 'Root' && currentUser) {
    next({ name: 'Dashboard' })
  } else next()
})

export default router
