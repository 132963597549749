// Imports
import Vue from 'vue'
import Vuex from 'vuex'
import { GIT_PROVIDERS } from '@/services/ProvidersConstants'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userFB: null,
    userDB: null,
    targetPage: null,
    alert: {
      message: '',
      isShow: false,
      isError: false,
      color: 'info'
    },
    tokens: {
      github: null,
      gitlab: null,
      bitbucket: null
    }
  },
  getters: {
    getUserDB: state => state.userDB,
    getUserFB: state => state.userFB,
    getTargetPage: state => state.targetPage,
    getAlert: state => state.alert,
    getAccessToken: state => {
      return gitProviderName => {
        switch (gitProviderName) {
          case GIT_PROVIDERS.github.name:
            return state.tokens.github
          case GIT_PROVIDERS.gitlab.name:
            return state.tokens.gitlab
          case GIT_PROVIDERS.bitbucket.name:
            return state.tokens.bitbucket
          default:
            throw new Error(`Wrong git provider name provided: ${gitProviderName}`)
        }
      }
    }
  },
  mutations: {
    SET_USER_DB(state, userDB) {
      state.userDB = userDB
    },
    SET_USER_FB(state, userFB) {
      state.userFB = userFB
    },
    SET_ALERT(state, alert) {
      state.alert = alert
    },
    SET_TARGET_PAGE(state, targetPage) {
      state.targetPage = targetPage
    },
    SET_ACCESS_TOKEN(state, payload) {
      if (payload && payload.token === undefined) {
        payload.token = null
      }
      switch (payload.provider) {
        case GIT_PROVIDERS.github.name:
          state.tokens.github = payload.token
          break
        case GIT_PROVIDERS.gitlab.name:
          state.tokens.gitlab = payload.token
          break
        case GIT_PROVIDERS.bitbucket.name:
          state.tokens.bitbucket = payload.token
          break
        default:
          throw new Error(`Wrong provider was provided: ${payload.provider}`)
      }
    }
  },
  actions: {
    setErrorAlert({ commit }, text) {
      const alert = {
        message: text,
        isShow: !!text,
        isError: true,
        color: 'accent'
      }

      commit('SET_ALERT', alert)
    },
    setSuccessAlert({ commit, dispatch }, text) {
      const alert = {
        message: text,
        isShow: !!text,
        isError: false,
        color: 'info'
      }

      commit('SET_ALERT', alert)

      setTimeout(() => dispatch('clearAlert'), 5000)
    },
    clearAlert({ commit }) {
      const alert = {
        message: '',
        isShow: false,
        isError: false,
        color: 'info'
      }

      commit('SET_ALERT', alert)
    },
    setUserDB({ commit }, userDB) {
      commit('SET_USER_DB', userDB)
    },
    setUserFB({ commit }, userFB) {
      commit('SET_USER_FB', userFB)
    },
    setTargetPage({ commit }, targetPage) {
      commit('SET_TARGET_PAGE', targetPage)
    },
    setAccessToken({ commit }, payload) {
      commit('SET_ACCESS_TOKEN', payload)
    }
  }
})
