<template>
  <section ref="backgroundSection" class="career">
    <v-row
      ref="contentSection"
      class="career__wrap"
      align="center"
      justify="center"
    >
      <v-col
        v-for="(step, i) in stepCareer"
        :key="i"
        class="text-center"
        cols="auto"
      >
        <v-card
          class="caree__item d-flex flex-column justify-space-between align-center"
          height="210"
          width="210"
          hover
        >
          <div class="d-flex justify-space-between pa-3" style="width: 100%">
            <span class="display-1">{{ step.numStep }}</span>
            <v-icon size="35">
              {{ step.isLock ? 'mdi-lock' : 'mdi-lock-open' }}
            </v-icon>
          </div>
          <v-avatar class="mb-8" size="80" tile>
            <v-img :src="step.linkImg" />
          </v-avatar>
          <v-icon
            v-if="step.isDone"
            size="38"
            color="white"
            style="position: absolute; top:30px"
          >
            mdi-checkbox-blank-circle
          </v-icon>
          <v-icon
            v-if="step.isDone"
            size="35"
            color="green"
            style="position: absolute; top:30px"
          >
            mdi-checkbox-marked-circle
          </v-icon>
          <v-rating
            v-if="step.isDone"
            style="position: absolute; bottom:30px"
            :value="step.rating"
            length="5"
            empty-icon="mdi-star-outline"
            full-icon="mdi-star"
            readonly
            size="33"
            dense
            color="yellow darken-3"
            background-color="amber darken-4"
          />
          <p class="primary--text pa-1 mb-0 subtitle-2" style="width: 100%;border-top: 1px solid black">
            {{ step.title }}
          </p>
        </v-card>
      </v-col>
    </v-row>
    <div class="stub">
      <h1 class="text">
        We are still working on this section to create something interesting :)
      </h1>
    </div>
  </section>
</template>

<script>
import api from '@/api'

export default {
  name: 'Career',
  data: () => ({
    topLineContentSection: null,
    headerHeight: null,
    balancer: 28,
    lengthAllScroll: null,
    stepCareer: []
  }),
  async mounted() {
    this.stepCareer = await api.career.getSteps()
    /* this.getStepCareer()
    window.addEventListener('scroll', this.handlerOnscroll)
    window.addEventListener('resize', this.getlengthAllScroll)
    this.getlengthAllScroll()
    this.handlerOnscroll() */
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handlerOnscroll)
    window.removeEventListener('resize', this.getlengthAllScroll)
  },
  methods: {
    getlengthAllScroll() {
      this.headerHeight = document.querySelector('.header').offsetHeight // header hight
      const windowHeight = document.documentElement.clientHeight
      const contentHeightSection = this.$refs.contentSection.offsetHeight
      this.lengthAllScroll = Math.abs(contentHeightSection - windowHeight - this.balancer) // full scroll length
    },
    handlerOnscroll() {
      const currentTopLineContentSection = this.$refs.contentSection.getBoundingClientRect().top
      this.topLineContentSection = currentTopLineContentSection
      let position = Math.floor(
        (Math.abs(currentTopLineContentSection) - this.headerHeight) / (this.lengthAllScroll / 100)
      ) // offset position
      this.$refs.backgroundSection.style.backgroundPositionY = `${position}%`
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.career {
  background: url(/img/AntHive.IO-Learning.png) no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: 50% 0%;
  height: 100%;
  transition: background-position 0.7s ease-out;
  &__item {
    border-radius: $border-radius-tile;
  }
}
.career__wrap {
  max-width: 1500px;
  margin: 0 auto;
}
.stub {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #2f1937c7;
  & .text {
    font-weight: 500;
    font-size: 40px;
    text-align: center;
    top: 45vh;
    left: 50%;
    transform: translate(-40%);

    position: fixed;
    color: $color-white;
  }
}
</style>
