import { externalApi } from '../plugins/axios'
import { APP, GIT_PROVIDERS } from './ProvidersConstants'
import OAuthProvider from './OAuthProvider'

class GitLabAPI {
  /**
   * Ctor
   * @param {String} clientId Client id from git provider website settings
   * @param {String} scope Scope from GIT_PROVIDERS (common/constants)
   */
  constructor(clientId, scope) {
    this._oAuthProvider = new OAuthProvider(GIT_PROVIDERS.gitlab, APP.api.urls.gitlab, clientId, scope)
  }
  /**
   * Redirects to authorization url
   */
  async authorize() {
    this._oAuthProvider.authorize()
  }
  /**
   * Loads access token and saves it
   * @param {String} code Authorization code
   */
  async loadAccessToken(code) {
    this._oAuthProvider.loadAccessToken(code)
  }
  /**
   * Return repositories of the user
   * @returns {Array<Object>} Repositories
   */
  async getRepositories(page, perPage) {
    // TODO Need to think about this part of code
    if (!this._oAuthProvider.isAuthenticated()) {
      this._oAuthProvider.authorize()
    }

    const token = this._oAuthProvider.getAccessToken()
    const url = `${GIT_PROVIDERS.gitlab.api.urls.root}/${GIT_PROVIDERS.gitlab.api.urls.getUserRepositories}`

    const response = await externalApi.get(url,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: {
          page,
          per_page: perPage
        }
      }
    )

    return response.data
  }
  /**
   * Returns count of repositories user has access to
   * @returns {Number} Repositories count
   */
  async getRepositoriesCount() {
    return 1 // TODO Parse link header and return repositories count Now I set it to max repos per page
  }
}

export const gitLabAPI = new GitLabAPI(
  `${GIT_PROVIDERS.gitlab.clientId}`,
  GIT_PROVIDERS.gitlab.api.scopes.readRepository
)
