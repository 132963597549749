<template>
  <v-dialog
    v-model="isShowPopup"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    tile
    max-width="1040"
    class="col-12"
  >
    <v-card color="#F4F2F7">
      <v-tabs
        v-model="tab"
        color="primary"
        show-arrows
      >
        <v-tabs-slider />
        <v-tab>Link your repo</v-tab>
        <v-tab>Public repo url</v-tab>
        <v-tab id="tour-step-3">
          Sandbox Link
        </v-tab>
        <v-tab @click="tourNextStepDelay(500)">
          Sandbox Sample Bot
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item eager>
          <v-card
            tile
            color="#F4F2F7"
            class="create-bot-tab"
            min-height="299"
            flat
          >
            <div class="tab-description">
              <h3>Continuous Integration</h3>
              <span>Choose the Git provider where the source code of your bot is hosted.
                When you push commits, new versions of your bot, we automatically
                compile and run your latest code.</span>
            </div>
            <CreateBotRepo :is-create-bot="isCreateBot" />
          </v-card>
        </v-tab-item>

        <v-tab-item eager>
          <v-card
            color="#F4F2F7"
            class="create-bot-tab"
            min-height="299"
            flat
          >
            <v-card-text>
              <div class="tab-description">
                <h3>Create your bot from a public git repository.</h3>
                <span>Copy/paste your anthive bot URL. Every time you push new code you can create
                  a new version from your
                  latest commit.
                  If your repository is private or you want automated versioning on push you can use
                  <span class="tab-link" @click="tab = 0">Link your repo</span>
                  option.
                </span>
              </div>
              <div
                class="create-bot-url"
              >
                <AntHiveInput :value.sync="repoUrl" />
                <AntHiveButton @click="createBotFromRepoUrl">
                  Create
                </AntHiveButton>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item eager>
          <v-card
            color="#F4F2F7"
            class="create-bot-tab"
            min-height="299"
            flat
          >
            <div class="tab-description">
              <h3>Create your sandbox bot from the existing sandbox link.</h3>
              <span>You can always edit/refactor the code and register a new version for the sandbox Bot.
                However it has a limitation of one file. If you want to use multilayer architecture,
                imports, OOP please create your bot from git repository.
                <span class="tab-link" @click="tab = 0">Link your repo</span>
                or
                <span class="tab-link" @click="tab = 1">Public repo url</span>.
              </span>
            </div>
            <div
              class="create-bot-url"
            >
              <AntHiveInput :value.sync="sandboxUrl" />
              <AntHiveButton @click="createBotFromSandboxUrl">
                Create
              </AntHiveButton>
            </div>
          </v-card>
        </v-tab-item>

        <v-tab-item eager>
          <v-card
            color="#F4F2F7"
            class="create-bot-tab"
            min-height="299"
            flat
          >
            <div class="tab-description">
              <h3>Create your sandbox bot from our samples.</h3>
              <span>You can always edit/refactor the code and register a new version for the sandbox Bot.
                However it has a limitation of one file. If you want to use multilayer architecture,
                imports, OOP please create your bot from git repository.
                <span class="tab-link" @click="tab = 0">Link your repo</span>
                or
                <span class="tab-link" @click="tab = 1">Public repo url</span>.
              </span>
            </div>
            <AntHiveButton
              v-for="(lang, index) in langs"
              :id="index === 0 ? 'tour-step-4' : ''"
              :key="lang.name"
              class="mr-2 mb-2"
              color="white"
              width="105"
              @click="createSandboxBotFromSample(lang.id, lang.sampleCode)"
            >
              {{ lang.name }}
            </AntHiveButton>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import CreateBotRepo from '@/components/CreateBotRepo'
import md5 from 'md5'
import { GIT_PROVIDERS } from '@/services/ProvidersConstants'

import Image from '@/mixins/image'

import api from '@/api'

export default {
  name: 'DashboardCreateBot',
  components: {
    CreateBotRepo
  },
  mixins: [Image],
  props: {
    isCreateBot: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      tab: 0,
      sandboxUrl: '',
      repoUrl: '',
      langs: null,
      isShowPopup: false
    }
  },
  watch: {
    isCreateBot() {
      this.isShowPopup = this.isCreateBot
    },
    isShowPopup(val) {
      this.$emit('update:isCreateBot', this.isShowPopup)
      if (!val) {
        this.tab = 0
        this.sandboxUrl = ''
        this.repoUrl = ''
      } else {
        this.tourNextStepDelay(500)
      }
    }
  },
  async mounted() {
    this.langs = await api.bots.getLangs()
    if (this.$route.query.box && this.$route.query.lang) {
      this.isShowPopup = true
      this.tab = 2
      const url = `${process.env.VUE_APP_WEBSITE_URL}/sandbox/${this.$route.query.lang}/?box=${this.$route.query.box}`
      this.sandboxUrl = url
    }
    if (this.$route.query.gitProvider && this.$route.query.code) {
      this.isShowPopup = true
    }
  },
  methods: {
    async createBotFromSandboxUrl() {
      const errorMessage = 'Oh, this url is not valid or sandbox does not exist\nexample: https://anthive.io/sandbox/js?box=e6ef3165ca0d1a688ff4d83203e12746'

      try {
        const url = new URL(this.sandboxUrl)
        const params = url.searchParams
        const box = params.get('box')

        const urlPath = url.pathname.split('/')
        const langUrl = urlPath[2]

        const langExtention = this.langs.find(lang => lang.extention === langUrl).extention
        if (!box || !langExtention) return

        const isExist = await api.games.checkGameExistence(box)
        if (!isExist) {
          this.setErrorAlert(errorMessage)
        }

        const repo = {
          type: 'public',
          provider: 'sandbox',
          name: box,
          owner: langExtention
        }

        const botId = await api.providers.createBotFromRepo(repo)
        if (botId) {
          this.$router.push({ name: 'EditBot', params: { id: botId } })
        }
      } catch {
        this.setErrorAlert(errorMessage)
      }
    },
    getBotLangId(langExtention) {
      const lang = this.langs.find(lang => lang.extention === langExtention)
      return lang.id
    },
    async createSandboxBotFromSample(lang, sampleCodeUrl) {
      const sampleCode = await api.games.getDefaultGameCode(sampleCodeUrl)
      const box = md5(sampleCode)

      const repo = {
        type: 'public',
        provider: 'sandbox',
        name: box,
        owner: lang
      }

      const botId = await api.providers.createBotFromRepo(repo)
      if (botId) {
        this.$router.push({ name: 'EditBot', params: { id: botId } })
      }
    },
    async createBotFromRepoUrl() {
      try {
        const repositoryUrl = new URL(this.repoUrl)
        const providerUrl = repositoryUrl.origin

        const providerList = Object.values(GIT_PROVIDERS)
        const gitProvider = providerList.find(provider => provider.website === providerUrl)
        if (!gitProvider) {
          this.setErrorAlert('Unknown git provider')
        }

        const repositoryPath = repositoryUrl.pathname.split('/').reverse()
        const [repoName, repoOwner] = repositoryPath
        if (!repoOwner || !repoName) {
          this.setErrorAlert('Invalid repository URL. Example: https://github.com/owner/repo')
        }

        const repo = {
          type: 'public',
          provider: gitProvider.name,
          name: repoName,
          owner: repoOwner
        }

        const botId = await api.providers.createBotFromRepo(repo)
        if (botId) {
          this.$router.push({ name: 'EditBot', params: { id: botId } })
        }
      } catch (err) {
        this.setErrorAlert(err)
      }
    },
    tourNextStepDelay(delay = 0) {
      if (this.$tours['tour'].currentStep === 2 || this.$tours['tour'].currentStep === 3) {
        setTimeout(() => this.$tours['tour'].nextStep(), delay)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';
.create-bot-tab {
  padding: 53px 120px;

  @media screen and (max-width: $screen-md) {
   padding: 10px 15px;
  }
}
.tab-link {
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}
.tab-description {
  color: $color-violet-350;
  font-weight: $font-weight-bold;
  margin-bottom: 39px;
  font-size: $font-big;
  h3 {
    margin-bottom: 6px;
  }
}
.create-bot-url {
  display: flex;
}
</style>
