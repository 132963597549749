<template>
  <AntHiveContainer title="Registration steps">
    <template #content>
      <v-row class="retistration-steps__content" cols="12">
        <v-col cols="12" sm="8">
          <iframe class="retistration-steps__video" src="https://www.youtube.com/embed/Dmx_o-6rOC0" frameborder="0" />
        </v-col>
        <v-col class="retistration-steps__steps" cols="12" sm="4">
          <div class="retistration-steps__step">
            <v-icon class="retistration-steps__icon active">
              mdi-numeric-1-circle
            </v-icon>
            <h2 class="retistration-steps__title">
              Registration
            </h2>
            <p class="retistration-steps__text">
              You can change your personal information in
              <router-link class="accent--text" to="/settings">
                Settings
              </router-link>
            </p>
          </div>
          <div class="retistration-steps__step">
            <v-icon class="retistration-steps__icon" :class="{ active: isUserHasBots }">
              {{ isUserHasBots ? 'mdi-numeric-2-circle' : 'mdi-numeric-2-circle-outline' }}
            </v-icon>
            <h2 class="retistration-steps__title">
              Create first bot
            </h2>
            <ul class="retistration-steps__text">
              <li>
                Go to <router-link class="accent--text" to="/bots">
                  Bots
                </router-link>
              </li>
              <li>Click New bot</li>
              <li>Put repo</li>
              <li>Select commit</li>
            </ul>
          </div>
          <div class="retistration-steps__step">
            <!-- TODO: games length -->
            <v-icon class="retistration-steps__icon" :class="{ active: isUserHasGames }">
              {{ isUserHasGames ? 'mdi-numeric-3-circle' : 'mdi-numeric-3-circle-outline' }}
            </v-icon>
            <h2 class="retistration-steps__title">
              Play first game
            </h2>
            <ul class="retistration-steps__text">
              <li>
                Go to <router-link class="accent--text" to="/new-game">
                  New Game
                </router-link>
              </li>
              <li>Select map</li>
              <li>Select your bot</li>
              <li>Select apponents</li>
              <li>Press Start Game</li>
              <li>Wait for queue</li>
            </ul>
          </div>
        </v-col>
      </v-row>
    </template>
  </AntHiveContainer>
</template>

<script>
export default {
  name: 'DashboardRegistrationSteps',
  props: {
    isUserHasBots: {
      type: Boolean,
      required: true,
      default: false
    },
    isUserHasGames: {
      type: Boolean,
      required: true,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.retistration-steps {
  &__content {
    padding: 16px;
  }
  &__video {
    width: 100%;
    height: 100%;
    max-height: 400px;
  }
  &__steps {
    max-width: 600px;
    padding-left: 40px;
  }
  &__step {
    position: relative;
    padding: 0 20px 0 50px;
    margin-bottom: 20px;
  }
  &__title {
    font-weight: 400;
  }
  &__icon {
    font-size: 40px;
    position: absolute;
    left: 0;
    top: 0;
    &.active {
      color: $color-violet-700;
    }
  }
}
</style>
