import { render, staticRenderFns } from "./DashboardCreateBot.vue?vue&type=template&id=53767fec&scoped=true&"
import script from "./DashboardCreateBot.vue?vue&type=script&lang=js&"
export * from "./DashboardCreateBot.vue?vue&type=script&lang=js&"
import style0 from "./DashboardCreateBot.vue?vue&type=style&index=0&id=53767fec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53767fec",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VCardText,VDialog,VTab,VTabItem,VTabs,VTabsItems,VTabsSlider})
