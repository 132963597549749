import { privateAnthiveApi } from '../plugins/axios'

const PROVIDERS_HANDLER = '/providers'

export const addGitAccessToken = async(gitProviderName, options) => {
  try {
    const url = `${PROVIDERS_HANDLER}/${gitProviderName}`
    const { data } = await privateAnthiveApi.post(url, options)
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const getUserRepos = async(provider) => {
  try {
    const reposUrl = `${PROVIDERS_HANDLER}/${provider}/repos`
    const { data } = await privateAnthiveApi.get(reposUrl)
    return data
  } catch ({ response }) {
    return response
  }
}

export const createBotFromRepo = async(repo) => {
  try {
    const url = `${PROVIDERS_HANDLER}/${repo.provider}/${repo.type}/${repo.owner}/${repo.name}`
    const { data } = await privateAnthiveApi.post(url)
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}
