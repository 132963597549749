<template>
  <div class="chip">
    <slot name="actions-top" />

    <div class="img" :style="`background: center / cover no-repeat url(${getImage(bot.avatar, 400)})`">
      <div class="bot-icons">
        <img
          class="lang-icon"
          width="40px"
          :src="getLangUrl(bot.lang)"
          :alt="bot.lang"
        >
        <img
          :src="getAntUrl(bot.skin)"
          alt="Bot skin"
          class="px-1"
          width="40px"
          height="40px"
        >
      </div>
    </div>
    <div class="description">
      <p class="bot-title">
        {{ bot.displayName }}<span class="version">v {{ bot.v }}</span>
      </p>
      <div class="stats">
        <div class="stats-row">
          <p>Games:</p>
          <p class="value">
            {{ bot.games }}
          </p>
        </div>
        <div class="stats-row">
          <p>W/L:</p>
          <p class="value">
            {{ bot.wins }}/{{ bot.losses }}
          </p>
        </div>
        <div class="stats-row">
          <p>MMR:</p>
          <p class="value">
            {{ bot.mmr }}
          </p>
        </div>
        <div class="stats-row">
          <p>ART:</p>
          <p class="value">
            {{ bot.art }}
          </p>
        </div>
        <div class="stats-row">
          <p>Errors:</p>
          <p class="value">
            {{ bot.err }}%
          </p>
        </div>
      </div>

      <slot name="actions-bottom" />
    </div>
  </div>
</template>

<script>
import Images from '@/mixins/image.js'

export default {
  name: 'AntHiveBotVertical',
  mixins: [Images],
  props: {
    bot: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.chip {
  width: 100%;
  max-width: 200px;
  height: 100%;
  max-height: 370px;
  margin: 15px;
  display: flex;
  flex-direction: column;
  box-shadow: $box-shadow-default;
  background-color: $color-white;
  position: relative;

  .img {
    position: relative;
    background-position: center;
    width: 100%;
    min-height: 120px;
    background: $color-red-300;
  }

  .active-switch {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
  }

  .bot-title {
    font-weight: $font-weight-bold;
    margin-bottom: 10px;
    display: block;
    height: 40px;
  }

  .bot-icons {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    * {
      background-color: $white;
      border-right: 2px solid $white;
      border-bottom: 2px solid $white;
    }
  }

  .version {
    margin-left: 10px;
    font-weight: $font-weight-normal;
    color: $color-violet-350;
  }

  .lang-name {
    font-size: $font-smaller;
    color: $color-violet-600;
  }

  .description {
    padding: 12px 18px;
    height: 100%;
    position: relative;

    .user-info {
      cursor: pointer;
      font-size: $font-medium;
      color: $color-white;
      margin: 0 auto 30px;
    }
  }

  .stats {
    .stats-row {
      display: flex;
      justify-content: space-between;
    }

    p {
      margin-bottom: 4px;
      font-size: $font-medium;
    }

    .value {
      font-weight: $font-weight-bold;
    }
  }

  .button {
    letter-spacing: 0;
  }
}
</style>
