<template>
  <v-card class="admin-users">
    <v-card-title>
      Users {{ users.length }}
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      />
    </v-card-title>
    <v-data-table
      sort-by="visit_last"
      sort-desc
      disable-pagination
      hide-default-footer
      :headers="headers"
      :items="users"
      :search="search"
    >
      <template v-slot:[`item.id`]="{ item }">
        <a :href="`${profileUrl}/dashboard?loginAs=${item.uid}`">
          {{ item.id }}
        </a>
      </template>
      <template v-slot:[`item.avatar`]="{ item }">
        <a :href="`${websiteUrl}/users/${item.username}`"><v-img width="40" :src="getImage(item.avatar, 40)" /></a>
      </template>
      <template v-slot:[`item.provider`]="{ item }">
        <v-icon>{{ getProviderIcon(item.provider) }}</v-icon>
      </template>
      <template v-slot:[`item.bots`]="{ item }">
        <span>{{ item.bots }}</span>
      </template>
      <template v-slot:[`item.visit_last`]="{ item }">
        <span>{{ timeAgo(item.visit_last) }}</span>
      </template>
      <template v-slot:[`item.date_create`]="{ item }">
        <span>{{ timeAgo(item.date_create) }}</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Images from '@/mixins/image.js'
import Time from '@/mixins/time.js'
import api from '@/api'
import flags from '@/assets/flags/data.json'

export default {
  name: 'AdminUsers',
  mixins: [Images, Time],
  data() {
    return {
      websiteUrl: process.env.VUE_APP_WEBSITE_URL,
      profileUrl: process.env.VUE_APP_PROFILE_URL,
      users: [],
      langs: [],
      search: '',
      headers: [
        {
          text: 'Id',
          align: 'start',
          value: 'id'
        },
        { text: 'Avatar', value: 'avatar' },
        { text: 'User name', value: 'username' },
        { text: 'Full name', value: 'full_name' },
        { text: 'Bots count', value: 'bots' },
        { text: 'Provider', value: 'provider' },
        { text: 'Visits', value: 'visits_count' },
        { text: 'Visit last', value: 'visit_last' },
        { text: 'Date create', value: 'date_create' }
      ]
    }
  },
  async mounted() {
    this.langs = await api.bots.getLangs()
    this.users = await api.users.getAllUsers()
  },
  methods: {
    getLangIcon(lang) {
      if (!lang) return null
      const icon = this.langs.find(language => language.name === lang)
      return icon.img
    },
    getLevelIcon(level) {
      let iconName = ''
      switch (level) {
        case 'Beginner':
          iconName = 'baby-face-outline'
          break
        case 'Professional':
          iconName = 'professional-hexagon'
          break
        case 'Enterprise':
          iconName = 'office-building-outline'
          break
        default:
          iconName = level
          break
      }
      return `mdi-${iconName}`
    },
    getProviderIcon(provider) {
      let iconName = ''
      switch (provider) {
        case 'google.com':
          iconName = 'google'
          break
        case 'github.com':
          iconName = 'github'
          break
        case 'password':
          iconName = 'account-key'
          break
        default:
          iconName = provider
          break
      }
      return `mdi-${iconName}`
    },
    getCountryFlagImg(country) {
      if (!country) return null
      const countryFlag = flags.find(countryFlag => countryFlag.name === country)
      return countryFlag.flag
    }
  }
}
</script>

<style>
.admin-users td,
.admin-users th {
  word-break: normal;
}
</style>
