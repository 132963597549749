import api from '@/api'

export default {
  methods: {
    getImage(id, width = 100, height = width) {
      return `${process.env.VUE_APP_API_URL}/public/images/${id}/${width}/${height}`
    },
    getLangUrl(lang) {
      return `${process.env.VUE_APP_WEBSITE_URL}/skins/lang/${lang}.png`
    },
    getAntUrl(skin) {
      return `${process.env.VUE_APP_WEBSITE_URL}/skins/client/${skin}/antBig.png`
    },
    getHiveUrl(skin) {
      return `${process.env.VUE_APP_WEBSITE_URL}/skins/client/${skin}/hiveBig.png`
    },
    async saveImage(file) {
      if (file.size > 10000000) {
        return Promise.reject(new Error('Use an image smaller than 10Mb'))
      }
      const formData = new FormData()
      formData.append('file', file)

      const data = await api.users.saveImage(formData)
      return data
    }
  }
}
