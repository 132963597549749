<template>
  <v-tour
    name="tour"
    :steps="tourSteps"
    class="tour"
  >
    <template slot-scope="tour">
      <transition name="fade">
        <v-step
          v-if="tour.steps[tour.currentStep]"
          :key="tour.currentStep"
          :step="tour.steps[tour.currentStep]"
          :previous-step="tour.previousStep"
          :next-step="tour.nextStep"
          :stop="tour.stop"
          :skip="tour.skip"
          :is-first="tour.isFirst"
          :is-last="tour.isLast"
          :labels="tour.labels"
        >
          <template>
            <div slot="content" class="content">
              <v-icon class="button-close" @click="tour.skip">
                mdi-close
              </v-icon>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div class="pt-2" v-html="tour.steps[tour.currentStep].content" />
            </div>
          </template>
          <!-- steps without actions -->
          <template v-if="tour.steps[tour.currentStep].params.disableButtons">
            <div slot="actions" />
          </template>
          <!-- first step -->
          <template v-else-if="tour.isFirst">
            <div slot="actions">
              <button class="button next" @click="tour.nextStep">
                Let's go!
              </button>
            </div>
          </template>
          <!-- last step -->
          <template v-else-if="tour.isLast">
            <div slot="actions">
              <button class="button next" @click="toGamesPage(tour)">
                To Games
              </button>
            </div>
          </template>
          <!-- enother steps -->
          <template v-else>
            <div slot="actions">
              <button class="button next" @click="tour.nextStep">
                Next
              </button>
            </div>
          </template>
        </v-step>
      </transition>
    </template>
  </v-tour>
</template>

<script>

export default {
  name: 'AntHiveTour',
  data() {
    return {
      tourSteps: [
        {
          target: '#tour-step-0',
          header: {
            title: 'ANTHIVE TOUR'
          },
          content: 'To help you get started with AntHive, we\'ll show you how to navigate in your profile, register your first bot and start a new game. <br><br>⏱️ It\'s about a 2 min. Ready ?',
          params: {
            placement: 'none',
            enableScrolling: false
          }
        },
        {
          target: '#tour-step-1',
          content: 'In <strong>Bots</strong> section you will see the list of all your bots',
          params: {
            placement: 'top',
            enableScrolling: false
          }
        },
        {
          target: '#tour-step-2',
          content: 'Let\'s register your first bot by clicking button <strong>Add bot</strong>',
          params: {
            disableButtons: true,
            placement: 'right'
          }
        },
        {
          target: '#tour-step-3',
          content: 'There are four options to register bot. Let\'s try option - <strong>Sandbox sample bot</strong>',
          params: {
            disableButtons: true,
            placement: 'top',
            enableScrolling: false
          }
        },
        {
          target: '#tour-step-4',
          content: 'Pick your favorite coding language. <br><br> <i>If you don\'t see it here please let us know in <a href="https://discord.gg/3Z7KvYv" target="_blank">Discord</a> channel. We\'re constantly extending the list of supported languages</i>',
          params: {
            disableButtons: true,
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '#tour-step-5',
          content: `Now you have a bot! You can modify name, avatar and skin by clicking on 🖊️ or active/inactive switch. When your bot is active it battles offline against other bots. Learn more about statistic and performance tracking in <a href="${process.env.VUE_APP_WEBSITE_URL}/rules/#bot" target="_blank">rules section</a>`,
          params: {
            placement: 'right'
          }
        },
        {
          target: '#tour-step-6',
          content: 'When you register the bot with <strong>Sandbox</strong> option, you can change code/strategy right in browser IDE. But the bot is limited to one file only <br><br><i>Please register your bot with <strong>git repository</strong> option for advance coding</i>',
          params: {
            placement: 'right'
          }
        },
        {
          target: '#tour-step-7',
          content: 'A new version of your bot created every time you change the code and/or push a commit. You can test, analyze performance of each version and quickly switch between them',
          params: {
            placement: 'right'
          }
        },
        {
          target: '#tour-step-8',
          content: 'Let\'s start your first battle!',
          params: {
            disableButtons: true,
            placement: 'left'
          }
        },
        {
          target: '#tour-step-9',
          content: 'You can customize map settings by picking a "theme", "size of the map" and "amount of food" randomly spawned on the map',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '#tour-step-10',
          content: 'Your bot can be along on the map, or it can battle against other bots. Let\'s try to pick an opponent for your first battle',
          params: {
            disableButtons: true,
            placement: 'top',
            enableScrolling: false
          }
        },
        {
          target: '#tour-step-11',
          content: 'When the battleground is set and opponents are ready the only one thing left is <br><strong>Start the battle!</strong> Let\'s do it',
          params: {
            disableButtons: true,
            placement: 'bottom'
          }
        },
        {
          target: '#tour-step-12',
          content: 'Your battle has been placed to a queue. Where it will sit until one of our servers pick it up and executes it on background. When it\'s done <i>usually takes 20 second</i> you\'ll receive notification and can watch/analyze step by step replay',
          params: {
            placement: 'top'
          }
        },
        {
          target: '#tour-step-13',
          content: 'As soon as your battle is out of the queue, let\'s watch replay and analyze your bot performance',
          params: {
            placement: 'none',
            enableScrolling: false
          }
        }
      ]
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.$route.query.mode === 'tour') {
        this.$tours['tour'].start()
      }
    }, 1000)
  },
  methods: {
    toGamesPage(tour) {
      tour.skip()
      window.open(`${process.env.VUE_APP_WEBSITE_URL}/games`, '_blank')
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/style/global.scss';

.tour {
  .v-step .v-step__arrow--dark,
  .v-step .v-step__arrow {
    border-color: transparent!important;
  }
  .v-step {
    background: $white!important;
    color: $violet!important;
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }

  .v-step[x-placement^=bottom] .v-step__arrow {
    border-bottom-color: $white!important;
  }
  .v-step[x-placement^=right] .v-step__arrow {
    border-right-color: $white!important;
  }
  .v-step[x-placement^=left] .v-step__arrow {
    border-left-color: $white!important;
  }
  .v-step[x-placement^=top] .v-step__arrow {
    border-top-color: $white!important;
  }
  .v-step__header {
    color: $violet;
    background-color: $white!important;
  }
  .button {
      border: 2px solid;
      border-radius: 2px;
      background: transparent;
      outline: none;
      margin: 15px 5px 0;
      padding: 0 5px;
      text-align: center;
      vertical-align: middle;
      white-space: nowrap;
  }
  .button-close {
    font-size: $font-big;
    color: $violet;
    position: absolute;
    top: 5px;
    right: 5px;
  }
}
</style>
