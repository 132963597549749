<template>
  <v-container fluid>
    <div v-if="title" class="d-flex justify-space-around">
      <div class="d-flex flex-column col-6">
        <span class="primary--text">{{ title }} <span v-if="requiredField" class="red--text">*</span></span>
        <span class="secondary--text">{{ subtitle }}</span>
      </div>
      <div class="col-6">
        <v-select
          v-model="inputValue"
          dense
          outlined
        />
      </div>
    </div>
    <div v-if="!title" class="col-12">
      <v-select
        v-model="inputValue"
        dense
        outlined
      />
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'AntHiveSelect',
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    subtitle: {
      type: String,
      required: false,
      default: null
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      inputValue: this.value
    }
  },
  watch: {
    inputValue() {
      this.$emit('update:value', this.inputValue)
    }
  }
}
</script>
