<template>
  <section class="user">
    <v-container>
      <v-row class="user-background">
        <div class="image" :style="`background: center/ cover no-repeat url(${getUserBackground})`" />
        <div class="gradient">
          <div class="edit-button edit-background" @click="openChangeImageDialog('userBackground')">
            <v-icon>mdi-pencil</v-icon>
          </div>
          <input
            ref="userBackground"
            type="file"
            name="userBackground"
            accept="image/*"
            style="display: none;"
            @change="changeImage($event,'background')"
          >
        </div>
      </v-row>
      <div class="mt-n3">
        <div class="user-info-container">
          <v-avatar tile size="200">
            <v-img class="avatar" :src="getImage(getUserDB.avatar, 400)">
              <div class="edit-button edit-avatar" @click="openChangeImageDialog('userAvatar')">
                <v-icon>mdi-pencil</v-icon>
              </div>
              <input
                ref="userAvatar"
                type="file"
                name="userAvatar"
                accept="image/*"
                style="display: none;"
                @change="changeImage($event,'avatar')"
              >
            </v-img>
          </v-avatar>
          <div class="title">
            <div class="user-name">
              <h1>{{ getUserDB.fullName }}</h1>
              <div class="edit-button edit-fullname" @click="changeProfileField('fullName')">
                <v-icon>mdi-pencil</v-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="public-link">
        <a
          class="link"
          :href="`${websiteUrl}/users?username=${getUserDB.username}`"
        ><v-icon class="link-icon">mdi-link</v-icon>
          <span>To public page</span>
        </a>
      </div>
      <div id="tour-step-0" class="user-description">
        <div class="edit-button edit-description" @click="changeProfileField('description')">
          <v-icon>mdi-pencil</v-icon>
        </div>
        <p v-if="getUserDB">
          <span v-if="getUserDB.description">{{ getUserDB.description }}</span>
          <span v-else>Here you can put some info about yourself for your public profile.</span>
        </p>
      </div>
      <div class="socials">
        <a
          v-for="(social, index) in existedSocials"
          :key="index"
          :class="{empty: !social.filled}"
          class="social-link"
          :title="social.name"
          rel="noreferrer"
          target="_blank"
          @click="changeProfileField('socials')"
        >
          <v-img :src="`/img/${social.name}.png`" />
        </a>
      </div>
      <div v-if="bots" class="bots-section">
        <h3 id="tour-step-1" class="d-inline">
          Bots
        </h3>
        <div class="bots">
          <AntHiveBotVertical
            v-for="(bot, index) in bots"
            :key="index + 'bot'"
            :bot="bot"
          >
            <template v-slot:actions-bottom>
              <AntHiveButton
                color="primary"
                class="edit-btn"
                :to="{ name: 'EditBot', params: { id: bot.id } }"
              >
                Edit
              </AntHiveButton>
            </template>
          </AntHiveBotVertical>
          <div
            class="create-bot-chip"
          >
            <AntHiveButton
              id="tour-step-2"
              color="primary"
              class="create"
              @click="isCreateBot = true"
            >
              Add Bot
            </AntHiveButton>
          </div>
        </div>
      </div>
      <div v-if="false" class="games-section">
        <h3>{{ $t("userInfo.bestGames") }}</h3>
        <!--<div class="games">
          <AntHiveGameVertical
            v-for="(game, index) in games"
            :key="index + 'game'"
            :game="game"
          />
        </div>-->
      </div>
    </v-container>

    <v-dialog
      v-model="isChangeProfile"
      tile
      max-width="1040"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      class="col-12"
    >
      <DashboardProfileSettings
        :socials="existedSocials"
        :field-for-change="profileFieldForChange"
        @updateProfile="updateProfile"
        @closeDialog="isChangeProfile = false"
      />
    </v-dialog>

    <DashboardCreateBot :is-create-bot.sync="isCreateBot" />
  </section>
</template>

<script>
import Images from '@/mixins/image.js'
// import UserAchivementChip from '@/components/UserAchivementChip'
import AntHiveBotVertical from '@/components/AntHiveBotVertical'
import DashboardCreateBot from '@/components/DashboardCreateBot'
import DashboardProfileSettings from '@/components/DashboardProfileSettings'
import { mapGetters, mapActions } from 'vuex'

import api from '@/api'

export default {
  name: 'DashboardProfile',
  components: {
    // UserAchivementChip,
    AntHiveBotVertical,
    DashboardProfileSettings,
    DashboardCreateBot
  },
  mixins: [Images],
  props: {
    bots: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      // TODO: Perhaps we need to transfer the list of socials
      // to the website and get them from there
      existedSocials: [
        {
          prefix: 'https://www.facebook.com/',
          filled: false,
          name: 'facebook'
        },
        {
          prefix: 'https://twitter.com/',
          filled: false,
          name: 'twitter'
        },
        {
          prefix: 'https://www.linkedin.com/in/',
          filled: false,
          name: 'linkedIn'
        },
        {
          prefix: 'https://www.youtube.com/channel/',
          filled: false,
          name: 'youtube'
        },
        {
          prefix: 'https://www.instagram.com/',
          filled: false,
          name: 'instagram'
        },
        {
          prefix: 'https://www.twitch.tv/',
          filled: false,
          name: 'twitch'
        }
      ],
      isChangeProfile: false,
      profileFieldForChange: '',
      isCreateBot: false,
      filesImgAvatar: null,
      websiteUrl: process.env.VUE_APP_WEBSITE_URL
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),
    getUserBackground() {
      if (this.getUserDB.background) {
        return this.getImage(this.getUserDB.background, 1400, 225)
      }
      return '/img/user_background.png'
    }
  },
  async mounted() {
    this.compareSocials()
  },
  methods: {
    ...mapActions(['setErrorAlert']),
    async updateProfile() {
      const resp = await api.users.update(this.getUserDB)
      if (resp) {
        if (this.profileFieldForChange === 'socials') this.compareSocials()
        this.isChangeProfile = false
        this.profileFieldForChange = ''
      }
    },
    compareSocials() {
      this.existedSocials = this.existedSocials.map(social => {
        const userSocial = this.getUserDB.socials[social.name]
        social.filled = !!userSocial

        return social
      })
    },
    openChangeImageDialog(inputName) {
      this.$refs[inputName].click()
    },
    changeProfileField(field) {
      this.isChangeProfile = true
      this.profileFieldForChange = field
    },
    async changeImage(event, fieldName) {
      this.filesImgAvatar = event.target.files || event.dataTransfer.files
      if (!this.filesImgAvatar.length) return

      const imageId = await api.users.saveImage(this.filesImgAvatar[0])
      if (!imageId) return

      this.getUserDB[fieldName] = imageId
      await api.users.update(this.getUserDB)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.edit-button {
  cursor: pointer;
  width: 35px;
  height: 35px;
  background-color: $color-white;
  border-radius: $border-radius-default;

  i {
    width: 100%;
    height: 100%;
    color: $color-violet-450;
  }

  &:hover i{
    color: $violet-light;
  }
}

.user {
  padding-top: 40px;
}

.user-info-container {
  display: flex;

  @media screen and (max-width: $screen-md) {
   flex-direction: column;
  }
}

.user-name {
  padding: 100px 0 0 20px;
  display: flex;

  h1{
    @media screen and (max-width: $screen-md) {
      font-size: $font-big;
    }
  }

  @media screen and (max-width: $screen-md) {
   padding: 10px 0;
  }
}

.bots-section,
.games-section {
  margin-top: 90px;

  @media screen and (max-width: $screen-md) {
    margin: 0;
  }

  .bots,
  .games {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
}

.create-bot-chip {
  width: 100%;
  max-width: 200px;
  height: 370px;
  margin: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: $box-shadow-default;
  background-color: $color-white;

  .description {
    padding: 12px 18px;
    height: 100%;
    position: relative;
  }

  .img {
    background-position: center;
    width: 100%;
    min-height: 120px;
    background: $color-violet-350;
  }

  .img-icon {
    font-size: 80px;
    color: white;
    margin: 20px auto;
    display: flex;
  }

  .create {
    display: flex;
    width: 123px;
    margin: 0 auto;
   }

  .bot-title {
    padding: 68px 10px;
    text-align: center;
    margin: 0;
   }
}

.games {
  margin-top: 40px;
}

.user-background {
  position: absolute;
  top: 0;
  height: 225px;
  left: 0;
  right: 0;
  margin: 0;

  .edit-background {
    position: absolute;
    right: 32px;
    bottom: 10px;
  }
}

.image {
  width: 100%;
}

.gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  background: $user-background-gradient;
}

.avatar {
  background-color: $color-white;
  border: 7px solid $color-white;
  position: relative;
}

.edit-avatar {
  position: absolute;
  top: 7px;
  right: 8px;
}

.edit-fullname {
  margin-left: 10px;
}

.public-link {
  margin-top: 13px;

  @media screen and (max-width: $screen-md) {
    margin: 0;
  }

  .link,
  .link-icon {
      color: $violet-light;
  }

  .link {
    display: inline;
  }

  .link-icon {
    margin-bottom: 1px;
    margin-right: 10px;
  }
}

.user-description {
  padding-top: 31px;
  margin-top: 48px;
  margin-right: 65px;
  border-top: 1px solid $lilac;
  position: relative;

  @media screen and (max-width: $screen-md) {
    margin-top: 20px;
    margin-right: 50px;
  }

  .edit-description {
    position: absolute;
    top: -17px;
    right: -45px;
  }
}

.title {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: $screen-md) {
   margin: 0;
  }
}

.socials {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 40px;
}

.social-media {
  display: flex;
}

.social-link {
  margin-right: 25px;
  margin-bottom: 25px;
  width: 30px;

  &.empty {
    filter: opacity(0.2);
  }
}

.edit-btn {
  display: flex;
  width: 123px;
  margin: 5px auto;
}
</style>
