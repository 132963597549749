<template>
  <AntHiveContainer v-if="games.length" title="Games Queue">
    <template #content>
      <transition-group name="list" class="queue">
        <v-card
          v-for="game in games"
          :key="game.id"
          tile
          class="queue__game-card"
          width="200"
        >
          <span class="queue__bot-name">{{ game.id }}</span>
          <span class="queue__author-name">by {{ game.author }}</span>
          <div class="queue__bots">
            <div
              v-for="(bot, index) in game.bots"
              :key="game.id + index"
              class="queue__bot"
            >
              <v-avatar tile class="queue__bot-avatar" size="35">
                <v-img :src="getImage(bot.avatar, 60)" />
              </v-avatar>
              <div class="queue__bot-info">
                <span class="queue__bot-name">{{ bot.name }}</span>
                <span class="queue__bot-lang">{{ bot.lang }}</span>
              </div>
            </div>
          </div>
        </v-card>
      </transition-group>
    </template>
  </AntHiveContainer>
</template>

<script>
import Images from '@/mixins/image.js'
import AntHiveContainer from '@/components/AntHiveContainer'

export default {
  name: 'NewGameQueueGames',
  components: {
    AntHiveContainer
  },
  mixins: [Images],
  data() {
    return {
      games: []
    }
  },
  created() {
    const gamesRef = this.$firebase.database().ref('games').limitToLast(100)
    gamesRef.on('value', (snapshot) => {
      this.games = snapshot.val() ? Object.values(snapshot.val()) : []
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.queue {
  flex-wrap: wrap;
  display: flex;

  &__game-card {
    margin-right: 32px;
    margin-top: 16px;
    padding: 10px;
  }

  &__author-name {
    text-align: center;
    display: block;
    margin: 5px 0 10px;
    font-weight: 600;
    color: $color-violet-700 !important;
  }

  &__bot {
    margin-bottom: 10px;
    display: flex;
  }

  &__bot-avatar {
    margin-right: 10px;
  }

  &__bot-name {
    font-size: $font-small;
    color: $color-violet-700;
  }

  &__bot-lang {
    display: block;
    margin-top: -5px;
    font-size: $font-small;
    color: $color-violet-500 !important;
    text-transform: capitalize;
  }

  .list-item {
    display: inline-block;
    margin-right: 10px;
  }

  .list-enter-active,
  .list-leave-active {
    transition: all 0.5s;
  }

  .list-enter,
  .list-leave-to {
    opacity: 0;
    transform: translateY(30px);
  }
}
</style>
