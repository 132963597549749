import { privateAnthiveApi, publicAnthiveApi } from '../plugins/axios'
import store from '../store'

const USER_HANDLER = '/user'

export const isUsernameAvailable = async(username) => {
  try {
    const { data } = await publicAnthiveApi.get(USER_HANDLER, { params: { username } })
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const update = async(user) => {
  try {
    const payload = JSON.stringify(user)
    const { data } = await privateAnthiveApi.post(USER_HANDLER, payload)
    store.dispatch('setSuccessAlert', 'Profile successfully updated')
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const getUser = async() => {
  try {
    const { data } = await privateAnthiveApi.get(USER_HANDLER)
    return data
  } catch ({ response }) {
    if (response.status === 404) {
      return null
    }
  }
}

export const saveImage = async(image) => {
  try {
    if (image.size > 10000000) {
      store.dispatch('setErrorAlert', 'Use an image smaller than 10Mb')
      return
    }

    const formData = new FormData()
    formData.append('file', image)

    const { data } = await privateAnthiveApi.post('/user/image', formData)
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const readUserNotification = async(id) => {
  try {
    const { data } = await privateAnthiveApi.post(`/user/notifications/${id}`)
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const getUserProviders = async() => {
  try {
    const { data } = await privateAnthiveApi.get('/user/providers')
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const getUserNotifications = async() => {
  try {
    const { data } = await privateAnthiveApi.get('/user/notifications')
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const getAllUsers = async() => {
  try {
    const { data } = await privateAnthiveApi.get('/admin/users')
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const getUserSkins = async() => {
  try {
    const reposUrl = `${USER_HANDLER}/skins`
    const { data } = await privateAnthiveApi.get(reposUrl)
    return data
  } catch ({ response }) {
    return response
  }
}
