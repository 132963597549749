<template>
  <v-expansion-panels
    v-if="collapsed"
    v-model="activePanelIndex"
    tile
    flat
  >
    <v-expansion-panel class="mb-5">
      <v-expansion-panel-header v-if="title" class="header">
        <template v-slot:actions>
          <v-icon color="accent" class="icon">
            mdi-triangle
          </v-icon>
        </template>
        <div class="header-content">
          <div class="title">
            {{ title }}
          </div>
          <div v-show="!isPanelOpen">
            <slot name="collapsed-content" />
          </div>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <slot name="content" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>

  <div v-else class="content">
    <div v-if="title" class="title">
      {{ title }}
    </div>
    <div class="body">
      <slot name="content" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'AntHiveContainer',
  props: {
    title: {
      type: String,
      default: ''
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false
    },
    containerName: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      activePanelIndex: 0
    }
  },
  computed: {
    isPanelOpen() {
      const currentPanelIndex = 0
      return this.activePanelIndex === currentPanelIndex
    }
  },
  watch: {
    activePanelIndex() {
      if (this.containerName) {
        localStorage[this.containerName] = this.isPanelOpen ? 'opened' : 'closed'
      }
    }
  },
  created() {
    if (localStorage[this.containerName]) {
      this.activePanelIndex = localStorage[this.containerName] === 'opened' ? 0 : null
    }
  }
}
</script>
<style lang="scss">
@import '@/assets/style/global.scss';
.header {
  .v-expansion-panel-header__icon {
    position: absolute;
    top: -7px;
    right: 25px;
  }
}

</style>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.header {
  min-height: 72px;
}
.content {
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
  background: $color-white;
}
.header-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.title {
  color: $color-violet-800;
  text-transform: capitalize;
  font-size: $font-big;
  font-weight: $font-weight-bold;
  margin-right: 40px;
}
.body {
  box-sizing: border-box;
  display: block;
  margin: 0px;
}
.field {
  padding: 16px 0;
  &:not(:last-child) {
    border-bottom: 2px solid $color-violet-500;
  }
}
</style>
