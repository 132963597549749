<template>
  <v-container class="pa-0" fluid>
    <div v-if="title" class="d-flex flex-wrap justify-space-between align-center">
      <div class="col-sm-6 d-flex flex-column col-12 pa-0">
        <span class="primary--text">{{ title }}</span>
        <span class="f-text-small secondary--text">{{ subtitle }}</span>
      </div>
      <div class="col-sm-6 col-12 pa-0">
        <v-switch
          v-model="switchValue"
          color="success"
          hide-details
        />
      </div>
    </div>
    <div v-if="!title" class="col-12 pa-0">
      <v-switch
        v-model="switchValue"
        color="success"
        hide-details
      />
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'AntHiveSwitch',
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    subtitle: {
      type: String,
      required: false,
      default: null
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      switchValue: this.value
    }
  },
  watch: {
    switchValue() {
      this.$emit('update:value', this.switchValue)
    }
  }
}
</script>
