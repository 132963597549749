import ax from 'axios'
import store from '../store'

// External
const externalApi = ax.create()

externalApi.interceptors.response.use(
  response => response,
  error => {
    if (error && error.response && error.response.status !== 404) {
      store.dispatch('setErrorAlert', error.response.data)
    }

    return Promise.reject(error)
  }
)

// Private Anthive API
const privateAnthiveApi = ax.create({
  baseURL: `${process.env.VUE_APP_API_URL}/private`,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
})
privateAnthiveApi.interceptors.request.use(
  async config => {
    const userFB = store.getters['getUserFB']
    const token = await userFB.getIdToken()
    config.headers['X-Auth-Token'] = token

    const urlParams = new URLSearchParams(window.location.search)
    const loginAs = urlParams.get('loginAs')

    if (loginAs && loginAs.length === 28) {
      if (config.params) {
        config.params.loginAs = loginAs
      } else {
        config.params = { loginAs }
      }
    }

    return config
  },

  error => {
    return Promise.reject(error)
  }
)

privateAnthiveApi.interceptors.response.use(
  response => response,
  error => {
    if (error && error.response && error.response.status !== 404) {
      store.dispatch('setErrorAlert', error.response.data)
    }

    return Promise.reject(error)
  }
)

// Public Anthive API
const publicAnthiveApi = ax.create({
  baseURL: `${process.env.VUE_APP_API_URL}/public`,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
})

publicAnthiveApi.interceptors.response.use(
  response => response,
  error => {
    if (error && error.response && error.response.status !== 404) {
      store.dispatch('setErrorAlert', error.response.data)
    }

    return Promise.reject(error)
  }
)
export { externalApi, privateAnthiveApi, publicAnthiveApi }
