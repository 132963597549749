<template>
  <v-app-bar height="82" :class="{ 'dashboard': isDashboardRoute }" class="header">
    <v-container class="d-flex align-center">
      <v-icon v-if="$vuetify.breakpoint.smAndDown && getUserDB.id" text @click="openDrawer">
        mdi-menu
      </v-icon>
      <h1 v-if="$route.meta.title" class="page-title">
        {{ $route.meta.title }}
      </h1>
      <v-spacer />
      <!-- Desktop menu -->
      <div class="hidden-sm-and-down">
        <a class="link" :href="`${websiteUrl}/games`">Games</a>
        <a class="link" text :href="`${websiteUrl}/leaderboard`">Leaderboard</a>
        <a class="link" text :href="`${websiteUrl}/rules`">Rules</a>
        <a class="link" text :href="`${websiteUrl}/sandbox`">Sandbox</a>
        <v-menu
          v-model="isShowNotifications"
          class="notifications"
          bottom
          nudge-bottom="50"
          nudge-right="130"
          left
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-badge
              offset-x="35"
              offset-y="10"
              color="pink"
              dot
              :value="isUserHasNotifications"
            >
              <v-icon class="notification-bell" v-on="on">
                mdi-bell
              </v-icon>
            </v-badge>
          </template>
          <v-card
            tile
            width="320"
            max-height="550"
          >
            <v-list class="notification-container">
              <v-list-item>
                Notifications
              </v-list-item>
              <v-list-item
                v-for="(notification, index) in notifications"
                :key="notification.id"
                class="notification"
                :class="notification.status"
                @click="readNotification(notification, index)"
              >
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-html="notification.text" />
                <div class="time-ago">
                  {{ timeAgo(notification.created) }}
                </div>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </div>
      <!-- User chip -->
      <v-menu v-if="getUserDB.id" v-model="menu" transition="scale-transition">
        <template v-slot:activator="{ on }">
          <v-card
            width="150"
            label
            class="user-chip"
            v-on="on"
          >
            <v-avatar tile size="35" class="user-chip-avatar">
              <v-img :src="getImage(getUserDB.avatar, 100)" />
            </v-avatar>
            <span class="user-chip-username">{{ getUserDB.username }}</span>
          </v-card>
        </template>
        <!-- User chip inner -->
        <v-card class="user-chip-inner" width="150">
          <v-list>
            <v-list-item>
              <v-avatar tile size="35" class="user-chip-avatar">
                <v-img :src="getImage(getUserDB.avatar, 100)" />
              </v-avatar>
              <span class="user-chip-username">{{ getUserDB.username }}</span>
            </v-list-item>
          </v-list>
          <v-list tile class="user-chip-inner-menu">
            <v-list-item @click="handlerClickLogout">
              <v-list-item-content>
                <v-list-item-title class="f-text-small">
                  Log out
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-container>
  </v-app-bar>
</template>

<script>
import api from '@/api'
import Fire from '@/plugins/firebase'

import Images from '@/mixins/image.js'
import Time from '@/mixins/time.js'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TheHeader',
  mixins: [Images, Time],
  props: {
    isOpenMobileDrawer: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      menu: false,
      notifications: [],
      isShowNotifications: false,
      notificationsRef: null
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),
    websiteUrl() {
      return process.env.VUE_APP_WEBSITE_URL
    },
    isDashboardRoute() {
      return this.$route.name === 'Dashboard'
    },
    isUserHasNotifications() {
      return this.notifications.some(notification => notification.status === 'new')
    }
  },
  async mounted() {
    this.notifications = await api.users.getUserNotifications()
    this.awaitNewNotifications()
  },
  destroyed() {
    this.notificationsRef.off()
  },
  methods: {
    ...mapActions(['setUserDB', 'setUserFB']),
    async readNotification(notification, index) {
      if (notification.status === 'read') return
      const resp = await api.users.readUserNotification(notification.id)
      if (resp) {
        this.notifications[index].status = 'read'
      }
    },
    awaitNewNotifications() {
      this.notificationsRef = this.$firebase.database().ref('notifications')
      this.notificationsRef.on('value', (snapshot) => {
        const newNotifications = snapshot.val() ? Object.values(snapshot.val()) : []

        newNotifications.forEach(notification => {
          if (notification.userToId !== this.getUserDB.id) return

          const isUserHasNotification = this.notifications.some(notif => notif.id === notification.id)
          if (isUserHasNotification) return

          this.notifications = [notification, ...this.notifications]
        })
      })
    },
    openDrawer() {
      this.$emit('update:isOpenMobileDrawer', true)
    },
    async handlerClickLogout() {
      this.$router.push({ name: 'SignIn' })
      await Fire.auth().signOut()
      this.menu = false
      localStorage.clear()
      this.$cookie.delete('userName', { domain: 'anthive.io' })
      this.$cookie.delete('userAvatar', { domain: 'anthive.io' })
      this.setUserFB(null)
      this.setUserDB(null)
    }
  }

}
</script>

<style lang="scss">
@import '@/assets/style/global.scss';
.notification-container {
  background-color: $color-violet-450;
  padding: 0;

  .notification {
    display: inherit;
    color: $violet !important;
    padding: 10px 20px;
    border-top: 1px solid $color-grey-100;

    &.read {
      background: $color-grey-50;
      cursor: default;
    }

    .time-ago {
      color: $color-grey-500;
    }
  }
}
</style>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';
.header {
  z-index: 1;
  box-shadow: none !important;
  background-color: $color-grey-50;
  color: $color-white;
  position: relative;

  &.dashboard {
    background-color: transparent !important;
    .link,
    .page-title,
    .notification-bell {
      color: $color-white
    }
  }

  .link {
    padding: 10px 20px;
    margin: 0 25px;
    font-weight: 600;
    color: $color-violet-700;
    text-decoration: none !important;

    &:hover {
      border-bottom: 2px solid;
    }

    &.nuxt-link-active {
      border-bottom: 2px solid;
    }
  }

  .notification-bell {
    cursor: pointer;
    margin: 0 25px;
    font-weight: 600;
    color: $color-violet-700;
  }

  .page-title {
    font-weight: 500;
    font-size: $font-bigger;
    color: $color-violet-700;
    @media screen and (max-width: $screen-md) {
      font-size: $font-medium;
      margin-left: 10px;
    }
  }

  .user-chip {
    border-radius: $border-radius-tile !important;
    padding: 8px 15px;
    background: white !important;
    font-size: $font-small;
    box-shadow: $box-shadow-default;
    font-weight: 500;
  }

  .user-chip-username {
    color: $color-violet-700 !important;
  }

  .user-chip-inner {
    border-radius: $border-radius-tile !important;
    background: white !important;
    font-size: $font-small;
    box-shadow: $box-shadow-default;
    color: $color-violet-700 !important;
    font-weight: 500;
    &-menu {
      border-radius: $border-radius-tile !important;
      margin: auto;
      border-radius: 0;
      border-top: 2px solid $color-violet-500;
    }
  }
  .user-chip-avatar {
    border-radius: $border-radius-tile !important;
    margin-right: 15px;
  }
}
</style>
