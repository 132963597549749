<template>
  <section class="signin">
    <div class="bg-ornament-righ" />
    <div class="bg-ornament-left" />
    <AntHiveLoginContainer title="Sign Up">
      <v-text-field
        v-model="email"
        hide-details
        dense
        label="Email"
        outlined
        class="text-field"
        background-color="#fff"
        autocomplete="off"
      />
      <v-text-field
        ref="password"
        v-model="pass"
        hide-details
        dense
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        label="Password"
        outlined
        class="text-field"
        background-color="#fff"
        autocomplete="off"
        @keyup.enter="emailLogin"
        @click:append="showPassword = !showPassword"
      />
      <v-text-field
        v-if="!isSignIn"
        ref="confirmPass"
        v-model="confirmPass"
        hide-details
        dense
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        label="Confirm password"
        outlined
        class="text-field"
        background-color="#fff"
        autocomplete="off"
        @keyup.enter="userSignup"
        @click:append="showPassword = !showPassword"
      />
      <span v-show="error" class="danger--text mt-2 d-block">{{ error }}</span>
      <div class="mt-2 mb-6">
        <router-link
          v-if="isSignIn"
          class="text-none"
          text
          to="/reset-password"
          color="success"
        >
          Forgot Password?
        </router-link>
      </div>
      <div v-if="isSignIn" class="d-flex justify-space-between flex-wrap align-center">
        <v-col cols="12" sm="5" class="px-0 py-1">
          <AntHiveButton
            dark
            class="text-none"
            :loading="loading.btn"
            color="#9A64FF"
            block
            @click="emailLogin"
          >
            Log In
          </AntHiveButton>
        </v-col>
        <v-col class="px-0 py-1" cols="12" sm="5">
          <AntHiveButton
            class="text-none"
            block
            color="primary"
            @click="signInSignUpToggle"
          >
            Sign Up
          </AntHiveButton>
        </v-col>
      </div>
      <div v-else class="d-flex justify-space-between flex-wrap align-center">
        <v-col cols="12" sm="6" class="px-0 py-1">
          <AntHiveButton
            class="text-none"
            :loading="loading.btn"
            color="success"
            block
            @click="userSignup"
          >
            CREATE YOUR ACCOUNT
          </AntHiveButton>
        </v-col>

        <v-col cols="12" sm="5" class="px-0 py-1">
          <span>
            Already have an account?
            <span class="accent--text link" @click="signInSignUpToggle"> Log in here!</span>
          </span>
        </v-col>
      </div>
      <div>
        <span class="separate-text">OR</span>
      </div>
      <div class="socials">
        <v-col
          cols="12"
          sm="5"
          class="social-button"
          @click="googleLogin"
        >
          <div class="content-wrapper">
            <div class="logo-wrapper">
              <img src="/img/google.svg">
            </div>
            <span class="text-container">
              <span>Log in with Google</span>
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="5"
          class="social-button"
          @click="gitHubLogin"
        >
          <div class="content-wrapper">
            <div class="logo-wrapper">
              <img src="/img/github.svg">
            </div>
            <span class="text-container">
              <span>Log in with Github</span>
            </span>
          </div>
        </v-col>
      </div>
    </AntHiveLoginContainer>
  </section>
</template>

<script>
import AntHiveLoginContainer from '@/components/AntHiveLoginContainer.vue'
import Fire from '@/plugins/firebase'
import { mapActions } from 'vuex'

export default {
  name: 'SignIn',
  components: {
    AntHiveLoginContainer
  },
  data() {
    return {
      loading: {
        btn: false,
        google: false,
        github: false
      },
      showPassword: false,
      email: null,
      pass: null,
      confirmPass: null,
      error: null,
      isSignIn: true,
      rules: {
        required: value => !!value || ''
      }
    }
  },
  methods: {
    ...mapActions(['setErrorAlert']),
    signInSignUpToggle() {
      this.error = ''
      this.isSignIn = !this.isSignIn
    },
    async emailLogin() {
      if (!this.isSignIn) return
      this.loading.btn = true
      if (!this.email || !this.pass) {
        this.error = 'All fields are required'
        this.loading.btn = false
        return
      }
      try {
        await Fire.auth().signInWithEmailAndPassword(this.email, this.pass)
        this.$gtag.event('Signin with Email', { event_category: 'auth', event_label: 'emaillogin' })
      } catch (error) {
        this.setErrorAlert(error.message)
        this.error = error.message
        this.pass = null
      } finally {
        this.loading.btn = false
      }
    },
    async userSignup() {
      if (this.isSignIn) return
      this.error = null
      this.loading.btn = true
      if (!this.email || !this.pass || !this.confirmPass) {
        this.error = 'All fields are required'
        this.loading.btn = false
        return
      }
      if (this.pass !== this.confirmPass) {
        this.error = 'Passwords must be equal'
        this.loading.btn = false
        return
      }
      try {
        await Fire.auth().createUserWithEmailAndPassword(this.email, this.pass)
        this.$gtag.event('Signup with Email', { event_category: 'auth', event_label: 'signup' })
      } catch (error) {
        this.error = error.message
      } finally {
        this.pass = null
        this.confirmPass = null
        this.loading.btn = false
      }
    },
    async gitHubLogin() {
      this.loading.github = true
      this.error = null
      try {
        const githubProvider = new Fire.auth.GithubAuthProvider()
        await Fire.auth().signInWithPopup(githubProvider)
        this.$gtag.event('Signin with Github', { event_category: 'auth', event_label: 'githublogin' })
      } catch (error) {
        this.setErrorAlert(error.message)
        this.error = this.getDifferentCredetialErrorMessage(error)
      } finally {
        this.loading.github = false
      }
    },
    async googleLogin() {
      this.loading.google = true
      this.error = null
      try {
        await Fire.auth().signInWithPopup(new Fire.auth.GoogleAuthProvider())
        this.$gtag.event('Signin with Google', { event_category: 'auth', event_label: 'googlelogin' })
      } catch (error) {
        this.setErrorAlert(error.message)
        this.error = this.getDifferentCredetialErrorMessage(error)
      } finally {
        this.loading.google = false
      }
    },
    getDifferentCredetialErrorMessage(error) {
      if (error.code === 'auth/account-exists-with-different-credential') {
        const registeredProvider = error.credential.providerId === 'github.com' ? 'Google' : 'Github'
        return `Looks like you registered with ${registeredProvider} please use log in with ${registeredProvider} button to access your account`
      }
      return error.message
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.signin {
  height: 100%;
  background-image: $signin-background-gradient;
  position: relative;
  overflow: hidden;
  @media screen and (min-width: $screen-sm) {
    padding-top: 80px;
  }
  .text-field {
    margin-top: 30px !important;
  }
  .link {
    cursor: pointer;
  }
  .separate-text {
    text-transform: uppercase;
    color: $color-violet-700;
    margin: 1.5rem 0;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    align-items: center;
    grid-gap: 0.5rem;
    font-size: 0.9rem;
  }
  .separate-text::before {
    content: '';
    border-bottom: 2px solid $color-violet-700;
    -webkit-transform: translateY(-1px);
    -ms-transform: translateY(-1px);
    transform: translateY(-1px);
  }
  .separate-text::after {
    content: '';
    border-bottom: 2px solid $color-violet-700;
    -webkit-transform: translateY(-1px);
    -ms-transform: translateY(-1px);
    transform: translateY(-1px);
  }
  .socials {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .social {
    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-icon {
      max-width: 139px;
    }
  }
  .bg-ornament-left,
  .bg-ornament-righ {
    position: absolute;
    background-color: $color-violet-600;
    mask: url(/img/bg-ornament.svg) no-repeat
  }
  .bg-ornament-righ {
    width: 535px;
    right: -170px;
    height: 640px;
    top: -50px;
  }
  .bg-ornament-left {
    width: 535px;
    left: -38px;
    height: 640px;
    top: 392px;
    transform: scaleX(-1);
  }
  // google, github buttons
  .social-button {
    display: inline-block;
    color: #595d58;
    min-width: 210px;
    margin: 4px 0;
    padding: 0;
    border-radius: 1px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    transition: background-color .218s, border-color .218s, box-shadow .218s;
    &:hover {
      cursor: pointer;
      box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
    }
    &:active {
      background-color: #eee;
      transition: background-color 0.2s;
    }
    .content-wrapper {
      display: flex;
      height: 100%;
      width: 100%;
      border: 1px solid transparent;
    }
    & img {
      width: 22px;
      height: 22px;
    }
    .logo-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding: 10px;
      width: 48px;
    }
    .text-container {
      padding-right: 10px;
      vertical-align: top;
      display: inline-block;
      text-align: center;
      width: 100%;
      line-height: 48px;
    }
  }
}
</style>
