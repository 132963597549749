<template>
  <div>
    <v-progress-circular
      v-if="loading"
      class="loader-main"
      size="280"
      color="#362d59 "
      width="15"
      indeterminate
    />
    <div v-if="!loading">
      <TargetPage />
    </div>
    <p v-if="error" class="er">
      Something went wrong, please try again later
    </p>
  </div>
</template>

<script>
import TargetPage from './views/TargetPage'
import Images from '@/mixins/image.js'
import api from '@/api'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    TargetPage
  },
  mixins: [Images],
  data() {
    return {
      error: null,
      loading: true,
      userDB: null,
      targetPage: null,
      currentUser: null
    }
  },
  computed: {
    ...mapGetters(['getTargetPage', 'getUserDB'])
  },
  async created() {
    if (this.$route.name && this.$route.name !== 'SignIn' && this.$route.name !== 'Root') {
      this.setTargetPage(this.$route.fullPath)
    }
    this.$firebase.auth().onAuthStateChanged(async userFB => {
      if (userFB === null) {
        this.loading = false
        if (this.$route.name !== 'SignIn') {
          this.$router.push({ name: 'SignIn' })
          return
        }
        return
      }
      this.setUserFB(userFB)
      this.loading = true
      let userDB = await api.users.getUser()
      this.loading = false

      this.setUserDB(userDB)
      this.addUserCookie()

      if (this.getTargetPage !== null) {
        const targetPage = this.getTargetPage
        this.setTargetPage(null)
        this.loading = false
        if (targetPage !== this.$route.fullPath) {
          this.$router.push(targetPage)
          return
        }
        return
      }
      this.loading = false
      if (this.$route.name !== 'SignIn') return

      // if new user
      if (userFB.metadata.creationTime === userFB.metadata.lastSignInTime) {
        this.$router.push({ name: 'Dashboard', query: { mode: 'tour' } })
      } else {
        this.$router.push({ name: 'Dashboard' })
      }
    })
  },
  methods: {
    ...mapActions(['setTargetPage', 'setUserDB', 'setUserFB', 'addUser']),
    addUserCookie() {
      this.$cookie.set('userName', this.getUserDB.username, { expires: '1Y', domain: 'anthive.io' })
      this.$cookie.set('userAvatar', this.getUserDB.avatar, { expires: '1Y', domain: 'anthive.io' })
    }
  }
}
</script>

<style lang="scss">
.loader-main {
  display: flex !important;
  margin: 130px auto;
}
.er {
  padding: 20px;
  color: #000;
}
</style>
