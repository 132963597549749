import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: {
        primary: '#4c377f',
        accent: '#9a64ff',
        danger: '#e03e2f',
        success: '#6c5fc7',
        action: '#ff596c'
      }
    }
  }
})
