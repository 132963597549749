<template>
  <section class="login">
    <AntHiveLoginContainer title="Reset Password">
      <v-form v-model="valid">
        <AntHiveInput
          class="my-2"
          required-field
          placeholder="Email*"
          :value.sync="email"
          :rules="[v => !!v || 'Email is required']"
        />
        <AntHiveButton class="mt-3 mr-3" :to="{ name: 'SignIn' }" color="white">
          Cancel
        </AntHiveButton>
        <AntHiveButton
          class="mt-3"
          :disabled="!valid"
          :loading="loading"
          color="success"
          @click="changePassword"
        >
          confirm
        </AntHiveButton>
      </v-form>
    </AntHiveLoginContainer>
  </section>
</template>

<script>
import AntHiveLoginContainer from '@/components/AntHiveLoginContainer.vue'

export default {
  components: {
    AntHiveLoginContainer
  },
  data() {
    return {
      email: null,
      error: null,
      loading: false,
      valid: false
    }
  },
  methods: {
    changePassword() {
      if (!this.email) {
        this.error = 'Field is required'
        return
      }
      this.loading = true
      this.$firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(() => {
          history.back()
        })
        .catch(error => {
          this.loading = false
          this.error = error.message
          this.email = null
        })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.login {
  height: 100%;
  background: url('/img/login-background.jpg');
}
</style>
