<template>
  <div>
    <v-alert
      ref="alert"
      v-model="getAlert.isShow"
      :color="getAlert.color"
      top
      tile
      class="alert-bar"
    >
      <div class="alert-content">
        <v-btn
          dark
          class="close-btn"
          rounded
          text
          @click="closeErrorAlert"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="error-alert">
          {{ getAlert.message }}
        </div>
        <div v-if="getAlert.isError" class="report" @click="reportIssue">
          Report Issue
        </div>
      </div>
    </v-alert>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TheAlertBar',
  computed: {
    ...mapGetters(['getAlert'])
  },
  methods: {
    ...mapActions(['setErrorAlert']),
    closeErrorAlert() {
      this.setErrorAlert(null)
    },
    reportIssue() {
      const message = this.getAlert.message
      const platform = window.navigator.platform
      const browser = window.navigator.userAgent
      const currentUrl = window.location.href

      const url = encodeURI(
        `https://github.com/anthive/website/issues/new?title=${message}&labels=a Profile&body=Platform: ${platform}\rURL: ${currentUrl}\rBrowser: ${browser}`
      )

      this.closeErrorAlert()
      window.open(url)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';
.alert-bar {
  position: absolute;
  width: 100%;
  z-index: 1000;

  .alert-content {
      display: flex;
      align-items: center;
      color: $color-white
  }
}

.error-alert {
  text-transform: capitalize;
}
.report {
  color: $color-violet-700;
  font-weight: $font-weight-bold;
  cursor: pointer;
}

.close-btn,
.error-alert {
  margin-right: 10px;
}
</style>
