<template>
  <v-container class="dashboard" fluid>
    <DashboardRegistrationSteps
      v-if="!loading && false"
      :is-user-has-bots="isUserHasBots"
      :is-user-has-games="isUserHaseGames"
    />
    <DashboardProfile :bots="bots" />
    <GameTable />
  </v-container>
</template>

<script>
import DashboardRegistrationSteps from '@/components/DashboardRegistrationSteps'
import DashboardProfile from '@/components/DashboardProfile'
import GameTable from '@/components/GameTable'
import api from '@/api'

export default {
  name: 'Dashboard',
  components: {
    DashboardRegistrationSteps,
    DashboardProfile,
    GameTable
  },
  data: () => ({
    feedback: '',
    bots: [],
    games: {},
    loading: true
  }),
  computed: {
    isUserHasBots() {
      return this.bots.length > 0
    },
    isUserHaseGames() {
      return false // this.games.games && this.games.games.length > 0
    }
  },
  async created() {
    this.bots = await api.bots.getUserBots() || []
    this.loading = false
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.dashboard {
  background-color: $color-grey-50;
  height: 100%;
  padding: 0 30px;

  @media screen and (max-width: $screen-md) {
    padding: 5px;
  }
}
</style>
