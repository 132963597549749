var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"admin-users"},[_c('v-card-title',[_vm._v(" Users "+_vm._s(_vm.users.length)+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"sort-by":"visit_last","sort-desc":"","disable-pagination":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.users,"search":_vm.search},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":(_vm.profileUrl + "/dashboard?loginAs=" + (item.uid))}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":(_vm.websiteUrl + "/users/" + (item.username))}},[_c('v-img',{attrs:{"width":"40","src":_vm.getImage(item.avatar, 40)}})],1)]}},{key:"item.provider",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(_vm.getProviderIcon(item.provider)))])]}},{key:"item.bots",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.bots))])]}},{key:"item.visit_last",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.timeAgo(item.visit_last)))])]}},{key:"item.date_create",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.timeAgo(item.date_create)))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }