<template>
  <v-card
    class="settings-wrap"
    tile
  >
    <v-icon class="close-dialog" @click="$emit('closeDialog')">
      mdi-close
    </v-icon>
    <div class="change-profile">
      <v-col cols="12">
        <h3>
          Change {{ currentFieldName }}
        </h3>
      </v-col>
      <div v-if="fieldForChange === 'socials'" class="socials">
        <v-col
          v-for="(social, index) in socials"
          :key="index"
          cols="12"
        >
          <h4 class="name">
            {{ social.name }}
          </h4>
          <AntHiveInput
            :value.sync="getUserDB.socials[social.name]"
          >
            <template v-slot:prefix>
              {{ social.prefix }}
            </template>
          </AntHiveInput>
        </v-col>
        <v-col cols="12">
          <AntHiveButton color="primary" @click="$emit('updateProfile')">
            Update profile
          </AntHiveButton>
        </v-col>
      </div>

      <div v-else class="change-inputs">
        <v-col cols="12" md="8">
          <v-textarea
            v-if="fieldForChange === 'description'"
            v-model="getUserDB.description"
            :error="$v.getUserDB.description.$invalid"
            :error-messages="descriptionValidationsError"
            background-color="white"
            dense
            outlined
            autocomplete="off"
          />
          <AntHiveInput
            v-else
            :error="$v.getUserDB.fullName.$invalid"
            :error-message="fullNameValidationsError"
            :value.sync="getUserDB.fullName"
          />
        </v-col>
        <v-col class="mb-6" cols="12" md="4">
          <AntHiveButton :disabled="$v.getUserDB.$invalid" color="primary" @click="updateProfile">
            Update profile
          </AntHiveButton>
        </v-col>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'DashboardProfileSettings',
  mixins: [validationMixin],
  props: {
    socials: {
      type: Array,
      required: true
    },
    fieldForChange: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      fieldNames: {
        fullName: 'full name',
        description: 'profile description'
      },
      fullNameMaxLength: 30,
      descriptionMaxLength: 800
    }
  },
  validations() {
    return {
      getUserDB: {
        description: {
          maxLength: maxLength(this.descriptionMaxLength)
        },
        fullName: {
          required,
          maxLength: maxLength(this.fullNameMaxLength)
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getUserDB']),
    currentFieldName() {
      return this.fieldNames[this.fieldForChange]
    },
    fullNameValidationsError() {
      if (!this.$v.getUserDB.fullName.required) {
        return 'Full name is required field'
      }

      if (!this.$v.getUserDB.fullName.maxLength) {
        return `Full name must be up to ${this.fullNameMaxLength} characters`
      }

      return ''
    },
    descriptionValidationsError() {
      if (!this.$v.getUserDB.description.maxLength) {
        return `Description must be up to ${this.descriptionMaxLength} characters`
      }

      return ''
    }
  },
  methods: {
    updateProfile() {
      this.$emit('updateProfile')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.settings-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.close-dialog {
  position: absolute;
  top: 5px;
  right: 5px;
}

.change-profile {
  padding: 53px 120px;

  @media screen and (max-width: $screen-md) {
   padding: 0;
  }

  .change-inputs {
    display: flex;
    align-items: center;

    @media screen and (max-width: $screen-md) {
      flex-direction: column;
      align-items: baseline;
    }
  }

  .name {
    text-transform: capitalize;
  }
}
</style>
