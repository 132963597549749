<template>
  <v-container class="new-game" fluid>
    <!-- New Game Settings container -->
    <AntHiveContainer
      id="tour-step-9"
      title="Customize map settings"
      collapsed
      container-name="map"
    >
      <template #collapsed-content>
        <div class="collapsed-content">
          <div>
            <v-img
              max-height="40"
              max-width="40"
              :src="getImage(`${gameSettings.idServerSkin}-background.png`, 80)"
            />
          </div>
          <div>Food: {{ gameSettings.mapFood }}</div>
          <div>Width: {{ gameSettings.mapWidth }}</div>
          <div>Height: {{ gameSettings.mapHeight }}</div>
        </div>
      </template>
      <template #content>
        <div class="flex-wrap d-flex">
          <v-col class="d-flex flex-column pa-0" cols="12" sm="12">
            <span class="success--text">Select Map Skin</span>
          </v-col>
          <v-col cols="12" sm="12" class="pa-0">
            <div class="d-flex justify-space-between pa-0 mt-6">
              <v-avatar
                v-for="item in skinsServer"
                :key="item.id"
                tile
                class="map-skin"
                :class="{ active: gameSettings.idServerSkin === item.id }"
                :size="$vuetify.breakpoint.smAndDown ? '30': '100'"
                @click="gameSettings.idServerSkin = item.id"
              >
                <v-img :src="getImage(`${item.id}-background.png`, 100)" />
              </v-avatar>
            </div>
            <div class="col-12 pa-0 mt-6">
              <span class="success--text">Map Settings</span>
              <v-slider
                v-model="gameSettings.mapFood"
                color="success"
                class="mt-3"
                label="Food"
                thumb-label
                max="99"
                min="10"
              />
              <v-slider
                v-model="gameSettings.mapWidth"
                color="success"
                class="mt-3"
                label="Width"
                thumb-label
                max="99"
                min="10"
              />
              <v-slider
                v-model="gameSettings.mapHeight"
                color="success"
                class="mt-3"
                label="Height"
                thumb-label
                max="99"
                min="10"
              />
            </div>
          </v-col>
        </div>
      </template>
    </AntHiveContainer>

    <AntHiveContainer
      title="Pick your opponents"
      container-name="opponents"
      collapsed
    >
      <template #collapsed-content>
        <div class="d-flex">
          <div
            v-for="(botId, index) in gameSettings.selectedOpponentsId"
            :key="index"
            class="bot"
          >
            <v-tooltip bottom content-class="tooltip">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-avatar
                    tile
                    size="35"
                  >
                    <v-img :src="getImage(getBotById(botId).avatar, 70)" />
                  </v-avatar>
                </div>
              </template>
              <NewGameBotSelect :bot="getBotById(botId)" />
            </v-tooltip>
          </div>
        </div>
      </template>
      <template #content>
        <div class="flex-wrap d-flex">
          <v-col class="d-flex flex-wrap justify-space-between" cols="12">
            <v-col class="d-flex flex-column pa-0" cols="12" sm="5">
              <span class="primary--text">Select Bots <span class="red--text">*</span></span>
              <span class="f-text-small secondary--text">Select the bots you want to play with</span>
              <v-col
                class="pa-0"
                cols="8"
              >
                <v-progress-linear
                  color="success"
                  :value="(gameSettings.selectedOpponentsId.length / 7) * 100"
                  height="25"
                >
                  <span class="white--text">{{ gameSettings.selectedOpponentsId.length }} of 7 bots</span>
                </v-progress-linear>
              </v-col>
            </v-col>

            <v-spacer />
            <v-col
              id="tour-step-10"
              class="pa-0"
              cols="12"
              sm="5"
            >
              <v-autocomplete
                v-model="selectedBot"
                :disabled="isSearchDisabled"
                dense
                :items="opponents"
                outlined
                placeholder="Search opponents"
                return-object
                item-text="displayName"
                item-value="id"
                hide-details
                autocomplete="false"
                @change="onBotSelect"
              >
                <template v-slot:item="{ item }">
                  <div class="d-flex align-center">
                    <v-avatar tile class="ma-1" size="40">
                      <v-img :src="getImage(item.avatar, 80)" />
                    </v-avatar>
                    {{ item.displayName }}
                    <span class="bot-version">v.{{ item.v }}</span>
                    <v-avatar tile size="20">
                      <v-img :src="getLangUrl(item.lang)" />
                    </v-avatar>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
          </v-col>
        </div>
        <v-col cols="12" class="d-flex flex-wrap px-0">
          <AntHiveBotVertical
            v-for="(botId, index) in gameSettings.selectedOpponentsId"
            :key="index + 'bot'"
            class="relative"
            :bot="getBotById(botId)"
          >
            <template v-slot:actions-top>
              <v-icon class="remove-btn" @click="botRemove(index)">
                mdi-close
              </v-icon>
            </template>
          </AntHiveBotVertical>
        </v-col>
      </template>
    </AntHiveContainer>

    <div id="tour-step-13" class="d-flex justify-center mb-5">
      <AntHiveButton
        v-show="gameSettings.selectedOpponentsId.length"
        id="tour-step-11"
        x-large
        class="mr-4"
        color="success"
        :cooldown="10"
        :loading="loading"
        @click="handlerClickStart"
        @loadingIsOver="loading = false"
      >
        Start Game
      </AntHiveButton>
    </div>
    <NewGameQueueGames id="tour-step-12" />
  </v-container>
</template>

<script>
import NewGameBotSelect from '@/components/NewGameBotSelect'
import NewGameQueueGames from '@/components/NewGameQueueGames'
import api from '@/api'
import { mapGetters } from 'vuex'
import Images from '@/mixins/image.js'
import AntHiveBotVertical from '@/components/AntHiveBotVertical'

export default {
  name: 'NewGame',
  components: {
    AntHiveBotVertical,
    NewGameBotSelect,
    NewGameQueueGames
  },
  mixins: [Images],
  data: () => ({
    opponents: [],
    skinsServer: [],
    loading: false,
    selectedBot: null,
    gameSettings: {
      selectedOpponentsId: [],
      idServerSkin: 1,
      mapHeight: 27,
      mapWidth: 48,
      mapFood: 35
    }
  }),
  computed: {
    ...mapGetters(['getUserDB']),
    currentSkinServer() {
      if (!this.skinsServer) return ''
      return this.skinsServer.find(item => item.id === this.idServerSkin)
    },
    isSearchDisabled() {
      return this.gameSettings.selectedOpponentsId.length >= 7
    },
    currentUserBots() {
      return this.opponents.filter(u => u.userId === this.getUserDB.id)
    }
  },
  watch: {
    gameSettings: {
      deep: true,
      handler() {
        localStorage.gameSettings = JSON.stringify(this.gameSettings)
      }
    }
  },
  async mounted() {
    this.opponents = await api.bots.getOpponents()
    if (this.$tours['tour'].currentStep === 8) this.$tours['tour'].nextStep()
    const rematchGameId = this.$route.query.rematch
    const challangeBotId = parseInt(this.$route.query.challange)
    this.skinsServer = await api.games.getSkinsServer()
    if (localStorage.gameSettings) {
      this.setGameSettingsFromLocalStorage()
    }
    if (rematchGameId) await this.fetchGameSettings(rematchGameId)
    if (challangeBotId) this.setChallangeBot(challangeBotId)
  },
  methods: {
    async handlerClickStart() {
      this.loading = true
      this.$gtag.event('Start game', { event_category: 'started', event_label: 'newgame' })
      const game = this.makeGame()
      await api.games.start(game)
      if (this.$tours['tour'].currentStep === 11) this.$tours['tour'].nextStep()
    },
    getBotById(id) {
      return this.opponents.find(opponent => opponent.id === id)
    },
    setGameSettingsFromLocalStorage() {
      const localGameSettings = JSON.parse(localStorage.gameSettings)
      const existSelectedOpponentsId = localGameSettings.selectedOpponentsId.filter(selectedBotId => {
        return this.opponents.some(bot => bot.id === selectedBotId)
      })
      localGameSettings.selectedOpponentsId = existSelectedOpponentsId
      this.gameSettings = localGameSettings
    },
    makeGame() {
      return {
        author: this.getUserDB.username,
        mapSettings: {
          width: this.gameSettings.mapWidth,
          height: this.gameSettings.mapHeight,
          food: this.gameSettings.mapFood,
          hunger: 15,
          theme: this.gameSettings.idServerSkin
        },
        version: process.env.VUE_APP_SIM_VERSION,
        flavor: 'custom',
        limit: 1000,
        bots: this.getBotsWithPoints()
      }
    },
    getBotsWithPoints() {
      const botsId = [...this.gameSettings.selectedOpponentsId]
      const bots = botsId.map(botId => this.getBotById(botId))
      const points = []
      return bots.map(bot => {
        let isUniquePoint = false
        let point = {}
        while (!isUniquePoint) {
          point = {
            x: this.generateRandomCoord(this.gameSettings.mapWidth - 1),
            y: this.generateRandomCoord(this.gameSettings.mapHeight - 1)
          }
          isUniquePoint = !points.some(p => p.x === point.x && p.y === point.y)
        }
        points.push(point)
        bot.name = bot.displayName
        bot.version = bot.v
        bot.conversion = 7
        bot.spawn = point
        return bot
      })
    },
    generateRandomCoord(size) {
      let rand = 0 - 0.5 + Math.random() * (size - 0 + 1)
      return Math.abs(Math.round(rand))
    },
    onBotSelect() {
      if (this.gameSettings.selectedOpponentsId.length >= 7 || !this.selectedBot) return
      this.gameSettings.selectedOpponentsId.push(this.selectedBot.id)
      this.selectedBot = {}
      // once for opponent step
      if (this.$tours['tour'].currentStep === 10) this.$tours['tour'].nextStep()
    },
    botRemove(index) {
      this.gameSettings.selectedOpponentsId = this.gameSettings.selectedOpponentsId.filter((_, i) => i !== index)
    },
    async fetchGameSettings(rematchGameId) {
      const game = await api.games.getGameById(rematchGameId)
      const botsId = game.bots.map(bot => bot.id)
      this.gameSettings.selectedOpponentsId = botsId
      const { mapSettings } = game
      this.gameSettings.idServerSkin = mapSettings.theme
      this.gameSettings.mapFood = mapSettings.food
      this.gameSettings.mapHeight = mapSettings.height
      this.gameSettings.mapWidth = mapSettings.width
    },
    setChallangeBot(botId) {
      this.gameSettings.selectedOpponentsId = this.opponents
        .filter(opponent => opponent.id === botId)
        .map(opponent => opponent.id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.new-game {
  height: 100%;
  background-color: $color-grey-50;
  padding: 0 30px;

  @media screen and (max-width: $screen-md) {
    padding: 5px;
  }
}

.bot-version {
  color: $color-violet-600;
  font-size: $font-small;
  padding: 0 8px 0 3px;
}

.collapsed-content {
  display: flex;
  align-items: center;
  color: $color-violet-700;
  font-weight: 600;
  & > * {
    height: 40px;
    line-height: 40px;
    padding-right: 40px;
    padding-left: 40px;
    &:not(:last-child) {
      border-right: 2px solid $color-violet-500;
    }
  }
}

.remove-btn {
  top: 5px;
  right: 5px;
  position: absolute;
  z-index: 1;
  -webkit-text-stroke: 0.5px white;
  -webkit-text-fill-color: $color-black;
}

.bot {
  margin-right: 5px;
  padding: 0 5px;
  &:hover {
    box-shadow: $box-shadow-default;
  }
}
.tooltip {
  border-radius: $border-radius-tile !important;
  opacity: 1 !important;
  padding: 0;
}

.map-skin {
  cursor: pointer;
  &.active {
    box-shadow: $box-shadow-selected;
  }
}
</style>
