import { privateAnthiveApi, externalApi, publicAnthiveApi } from '../plugins/axios'

export const start = async(game) => {
  try {
    const payload = JSON.stringify(game)
    const { data } = await privateAnthiveApi.post('/games', payload)
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const runGame = async(lang, code, sha) => {
  try {
    const url = `${process.env.VUE_APP_API_URL}/public/sandbox/${lang}/${sha}`
    const { data } = await externalApi.post(url, code)
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const getDefaultGameCode = async(sampleCodeUrl) => {
  try {
    const { data } = await externalApi.get(sampleCodeUrl)
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const getSkinsServer = async() => {
  try {
    const url = `${process.env.VUE_APP_WEBSITE_URL}/skins/server/data.json`
    const { data } = await externalApi.get(url)
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const getGameById = async(id) => {
  try {
    const gameUrl = `/game/${id}`
    const { data } = await publicAnthiveApi.get(gameUrl)
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const getGameCode = async(sha, lang) => {
  try {
    const codeUrl = `${process.env.VUE_APP_SANDBOX_BUCKET}/${process.env.VUE_APP_SIM_VERSION}/${sha}.${lang}`
    const { data } = await externalApi.get(codeUrl)
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const getUserGames = async(username) => {
  try {
    const url = `${process.env.VUE_APP_API_URL}/public/games`
    const { data } = await externalApi.get(url, { params: { un: username } })
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const checkGameExistence = async(sha) => {
  try {
    const gameUrl = `${process.env.VUE_APP_SANDBOX_BUCKET}/${process.env.VUE_APP_SIM_VERSION}/${sha}.zip`
    const data = await externalApi.head(gameUrl)
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const getCommitsByRepo = async(repo) => {
  try {
    const repoUrl = `https://raw.githubusercontent.com/${repo}/master/ANTHIVE`
    await externalApi.get(repoUrl)
    const commitsUrl = `https://api.github.com/repos/${repo}/commits`
    const { data } = await externalApi.get(commitsUrl)
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}

export const getGameLogs = async(sha) => {
  try {
    const logsUrl = `${process.env.VUE_APP_SANDBOX_BUCKET}/${process.env.VUE_APP_SIM_VERSION}/${sha}-bot.txt`
    const { data } = await externalApi.get(logsUrl)
    return data
  } catch ({ response }) {
    if (response && response.data && response.data.error) return null
  }
}
