<template>
  <section>
    <div v-if="isShowProviders">
      <AntHiveButton
        v-for="gitProvider in gitProviders"
        :key="gitProvider.name"
        class="mr-2 mb-2"
        color="white"
        :disabled="gitProvider.name !== 'github'"
        @click="getGitRepos(gitProvider.name)"
      >
        <v-icon class="mr-2" small>
          {{ gitProvider.icon }}
        </v-icon>
        {{ gitProvider.name }}
      </AntHiveButton>
    </div>
    <div v-if="!isShowProviders && repoOwners.length">
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
            class="owner-dropdown"
            outlined
            color="primary"
            dark
            v-on="on"
          >
            <v-avatar class="mx-2" tile size="25">
              <v-img :src="selectedOwner.avatar" />
            </v-avatar>{{ selectedOwner.name }}
            ▼
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(owner, index) in repoOwners" :key="index" @click="selectedOwner = owner">
            <v-list-item-title>
              <v-avatar class="ml-2" tile size="25">
                <v-img :src="owner.avatar" />
              </v-avatar> {{ owner.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <div class="repo-container">
        <v-card
          v-for="(repository, index) in selectedOwner.repos"
          :key="index"
          max-width="679"
          tile
          class="py-4 px-2 mb-1 d-flex"
          @click="selectRepo(repository)"
        >
          <v-col
            cols="8"
            class="d-flex align-center"
          >
            <v-icon class="repo-icon">
              mdi-{{ selectedProvider }}
            </v-icon>
            <span class="repo-name">{{ selectedOwner.name }}/{{ repository.name }}</span>
          </v-col>
          <v-col cols="2">
            <span v-if="repository.isPrivate" class="repo-private">
              <v-icon class="private-icon">mdi-lock</v-icon> Private</span>
          </v-col>
          <v-col cols="2">
            <v-avatar class="repo-icon" tile size="25">
              <v-img :src="getLangUrl(repository.lang)" />
            </v-avatar>
            <v-icon class="select-repo">
              mdi-chevron-right-box
            </v-icon>
          </v-col>
        </v-card>
      </div>
    </div>
    <div v-if="!isShowProviders && !repoOwners.length">
      <span>Hmmm… we can’t find any repositories for anthive bot.
        Bot repository must contain
        <a href="https://github.com/anthive/js/blob/master/ANTHIVE">
          ANTHIVE
        </a>
        file in it.
        <a href="https://discord.gg/3Z7KvYv">Contact us for support</a>
      </span>
    </div>
  </section>
</template>

<script>
import { gitHubAPI } from '@/services/GitHubAPI'
import { gitLabAPI } from '@/services/GitLabAPI'
import { bitbucketAPI } from '@/services/BitbucketAPI'
import { APP, GIT_PROVIDERS } from '@/services/ProvidersConstants'

import Image from '@/mixins/image'

import api from '@/api'

export default {
  name: 'CreateBotRepo',
  mixins: [Image],
  props: {
    isCreateBot: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      source: '',
      isShowProviders: true,
      repoOwners: [],
      selectedOwner: {},
      selectedProvider: '',
      gitProviders: Object.values(GIT_PROVIDERS)
    }
  },
  watch: {
    isCreateBot(val) {
      if (!val) {
        this.isShowProviders = true
        this.repoOwners = []
        this.selectedOwner = {}
      }
    }
  },
  async mounted() {
    if (this.$route.query.gitProvider && this.$route.query.code) {
      this.isShowProviders = false
      const gitProvider = this.$route.query.gitProvider
      const code = this.$route.query.code

      const query = Object.assign({}, this.$route.query)
      delete query.code
      delete query.gitProvider
      this.$router.replace({ query })

      const payload = {
        code: code,
        redirectUrl: `${APP.api.urls.redirectUrl}${gitProvider}`
      }

      // TODO: Check this
      // this.$router.push({ name: 'Dashboard' })

      const result = await api.providers.addGitAccessToken(gitProvider, payload)

      if (result === 'ok') {
        await this.getGitRepos(gitProvider)
      }
    }
  },
  methods: {
    authorizeWith(gitProvider) {
      switch (gitProvider) {
        case GIT_PROVIDERS.github.name:
          gitHubAPI.authorize()
          break
        case GIT_PROVIDERS.gitlab.name:
          gitLabAPI.authorize()
          break
        case GIT_PROVIDERS.bitbucket.name:
          bitbucketAPI.authorize()
          break
        default:
          throw new Error(`Unknown git repository provider: ${gitProvider}`)
      }
    },
    async getGitRepos(providerName) {
      this.selectedProvider = providerName
      const resp = await api.providers.getUserRepos(providerName)
      this.isShowProviders = false

      if (resp && resp.status === 400 && resp.data === 'Error: need-verification-code') {
        this.authorizeWith(providerName)
        return
      }

      if (resp && resp.orgs && resp.orgs.length) {
        this.repoOwners = resp.orgs
        this.selectedOwner = this.repoOwners[0]
      }
    },
    async selectRepo(selectedRepo) {
      const type = selectedRepo.isPrivate ? 'private' : 'public'
      const repo = {
        type,
        provider: this.selectedProvider,
        name: selectedRepo.name,
        owner: this.selectedOwner.name
      }

      const resp = await api.providers.createBotFromRepo(repo)
      if (resp) {
        this.$router.push({ name: 'EditBot', params: { id: resp } })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';
.repo-container {
  max-width: 679px;
  height: 500px;
  overflow-y: scroll;
  z-index: 1;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: $color-violet-700;
  }

  .provider,
  .repo-private,
  .private-icon,
  .select-repo {
    color: $color-violet-350;
 }

  .repo-icon {
    font-size: 30px;
    margin-right: 20px;
  }

  .repo-name {
    color: $color-violet-700;
  }

  .private-icon {
    font-size: 12px;
  }

  .select-repo {
    font-size: 24px;
  }
}

.owner-dropdown {
  margin-bottom: 10px;
}
</style>
