const GIT_PROVIDERS = {
  github: {
    name: 'github',
    website: 'https://github.com',
    clientId: process.env.VUE_APP_GITHUB_CLIENT_ID,
    api: {
      urls: {
        root: 'https://api.github.com',
        oAuthAuthorize: 'login/oauth/authorize',
        oAuthGetAccessToken: 'login/oauth/access_token',
        getUserRepositories: 'user/repos'
      },
      scopes: {
        repo: 'repo'
      }
    },
    icon: 'mdi-github'
  },
  gitlab: {
    name: 'gitlab',
    website: 'https://gitlab.com',
    clientId: process.env.VUE_APP_GITLAB_CLIENT_ID,
    api: {
      urls: {
        root: 'https://gitlab.com/api/v4',
        oAuthAuthorize: 'oauth/authorize',
        oAuthGetAccessToken: 'oauth/token',
        getUserRepositories: 'projects'
      },
      scopes: {
        readRepository: 'read_api'
      }
    },
    icon: 'mdi-gitlab'
  },
  bitbucket: {
    name: 'bitbucket',
    website: 'https://bitbucket.org',
    clientId: process.env.VUE_APP_BITBUCKET_CLIENT_ID,
    api: {
      urls: {
        root: 'https://bitbucket.org',
        oAuthAuthorize: 'site/oauth2/authorize',
        oAuthGetAccessToken: 'site/oauth2/access_token',
        getUserRepositories: 'user/repos'
      },
      scopes: {
        repository: 'repository'
      }
    },
    icon: 'mdi-bitbucket'
  }
}

Object.freeze(GIT_PROVIDERS)

const APP = {
  api: {
    urls: {
      redirectUrl: `${process.env.VUE_APP_PROFILE_URL}/dashboard?gitProvider=`,
      root: process.env.VUE_APP_API_URL,
      providers: 'user/providers',
      github: {
        oAuthGetAccessToken: 'github/access-token',
        getUserRepositories: 'github/user-repos'
      },
      gitlab: {
        oAuthGetAccessToken: 'gitlab/access-token',
        getUserRepositories: 'gitlab/user-repos'
      },
      bitbucket: {
        oAuthGetAccessToken: 'bitbucket/access-token',
        getUserRepositories: 'bitbucket/user-repos'
      }
    }
  }
}

Object.freeze(APP)

export {
  GIT_PROVIDERS,
  APP
}
