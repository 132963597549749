<template>
  <v-footer class="footer pa-4 d-flex justify-space-between" tag="footer">
    <!-- Copyright -->
    <div col="12" md="3" class="footer__copyright">
      <span>AntHive.IO</span>
      <span class="mx-2">&copy;, {{ new Date().getFullYear() }} </span>
    </div>

    <!-- Social networks icons -->
    <div col="12" md="2" class="footer__social">
      <a href="https://twitter.com/anthiveio" title="Our Twitter page" target="_blank">
        <v-icon class="footer__social--icon">mdi-twitter</v-icon>
      </a>
      <a href="https://www.instagram.com/anthiveio/" title="Our Instagram accoutn" target="_blank">
        <v-icon class="footer__social--icon">mdi-instagram</v-icon>
      </a>
      <a href="https://www.youtube.com/channel/UCP9SHUqnSdGEiEHbqIudMxw" title="Our Youtube channel" target="_blank">
        <v-icon class="footer__social--icon">mdi-youtube</v-icon>
      </a>
      <a href="https://www.twitch.tv/anthiveio" title="Our Twitch accoutn" target="_blank">
        <v-icon class="footer__social--icon">mdi-twitch</v-icon>
      </a>
    </div>
  </v-footer>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.footer {
  padding: 16px;
  display: flex;
  justify-content: space-around;
  background: $color-grey-50 !important;
  @media screen and (max-width: $screen-md) {
    flex-direction: column;
  }
  &__social {
    margin-top: -3px;
  }
  &__social--icon {
    margin: 0 5px;
    color: $color-violet-700 !important;
  }
  &__copyright,
  &__social{
    color: $color-violet-700 !important;
    text-align: center;
    padding: 0 7px;
    @media screen and (max-width: $screen-lg) {
      margin: 3px 0;
    }
  }
}
</style>
