import { APP, GIT_PROVIDERS } from './ProvidersConstants'
import OAuthProvider from './OAuthProvider'

class GitHubAPI {
  /**
   * Ctor
   * @param {String} clientId Client id from git provider website settings
   * @param {String} scope Scope from GIT_PROVIDERS (common/constants)
   */
  constructor(clientId, scope) {
    this._oAuthProvider = new OAuthProvider(GIT_PROVIDERS.github, APP.api.urls.github, clientId, scope)
  }
  /**
   * Redirects to authorization url
   */
  async authorize() {
    this._oAuthProvider.authorize()
  }
  /**
   * Loads access token and saves it
   * @param {String} code Authorization code
   */
  async loadAccessToken(code) {
    this._oAuthProvider.loadAccessToken(code)
  }
}

export const gitHubAPI = new GitHubAPI(`${GIT_PROVIDERS.github.clientId}`, GIT_PROVIDERS.github.api.scopes.repo)
