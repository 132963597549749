<template>
  <div style="width: 100%">
    <v-text-field
      v-model="inputValue"
      :error="error"
      :error-messages="errorMessage"
      background-color="white"
      dense
      outlined
      autocomplete="off"
      :required-field="requiredField"
      :placeholder="placeholder"
      :rules="rules"
    >
      <span v-if="$slots.prefix" slot="prepend-inner" class="py-1">
        <slot name="prefix" />
      </span>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'AntHiveInput',
  props: {
    value: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false
    },
    rules: {
      type: Array,
      required: false,
      default: () => []
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      inputValue: this.value
    }
  },
  watch: {
    inputValue() {
      this.$emit('update:value', this.inputValue)
    }
  }
}
</script>
