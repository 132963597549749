import Vue from 'vue'

import AntHiveButton from '@/components/AntHiveButton'
import AntHiveInput from '@/components/AntHiveInput'
import AntHiveSelect from '@/components/AntHiveSelect'
import AntHiveSwitch from '@/components/AntHiveSwitch'
import AntHiveContainer from '@/components/AntHiveContainer'

import '../assets/style/fonts.css'
import '../assets/style/style.scss'
import '../assets/style/textures.css'

Vue.component('AntHiveButton', AntHiveButton)
Vue.component('AntHiveInput', AntHiveInput)
Vue.component('AntHiveSelect', AntHiveSelect)
Vue.component('AntHiveSwitch', AntHiveSwitch)
Vue.component('AntHiveContainer', AntHiveContainer)
