<template>
  <v-btn
    tile
    class="btn"
    :disabled="isDisabled"
    :color="color"
    :icon="icon"
    :loading="loading"
    :to="to"
    :dark="dark"
    :x-large="xLarge"
    :block="block"
    fill
    @click="handlerClick"
  >
    <slot class="text" />
    <template v-if="cooldown" v-slot:loader>
      <span>{{ seconds }}</span>
    </template>
  </v-btn>
</template>

<script>
export default {
  name: 'AntHiveButton',
  props: {
    cooldown: {
      type: Number,
      required: false,
      default: 0
    },
    disabled: {
      type: Boolean,
      required: false,
      default: null
    },
    color: {
      type: String,
      required: false,
      default: 'none'
    },
    icon: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    to: {
      type: Object,
      required: false,
      default: () => {}
    },
    dark: {
      type: Boolean,
      required: false,
      default: false
    },
    xLarge: {
      type: Boolean,
      required: false,
      default: false
    },
    block: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      loader: null,
      seconds: 0,
      count: null
    }
  },
  computed: {
    isDisabled() {
      return !!this.seconds || this.disabled
    }
  },
  watch: {
    seconds() {
      if (this.seconds <= 0) {
        clearInterval(this.count)
        this.loader = null
        this.$emit('loadingIsOver')
      }
    }
  },
  methods: {
    handlerClick() {
      this.$emit('click')
      if (!this.cooldown) return

      this.seconds = this.cooldown
      this.count = setInterval(() => {
        this.seconds -= 1
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.btn {
  min-height: 40px;
  box-shadow: none;
  text-transform: capitalize !important;
  .text {
    line-height: 1;
    font-weight: 600;
    font-size: $font-big;
  }
}
</style>
