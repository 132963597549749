var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:"drawer",attrs:{"permanent":_vm.$vuetify.breakpoint.mdAndUp,"mini-variant":!_vm.isOpen,"mini-variant-width":"60","width":"220","app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"drawer__list-wrap"},[_c('div',{staticClass:"drawer__main-list"},[_c('v-list',{staticClass:"py-0",attrs:{"dense":"","nav":""}},[_c('div',{on:{"click":_vm.handlerClickLogo}},[_c('TheLogo',{attrs:{"small":!_vm.isOpen,"light":""}})],1),_vm._l((_vm.topNavItems1),function(item,index){return _c('v-tooltip',{key:item.name + index,attrs:{"disabled":_vm.isOpen,"nudge-left":"25","color":"black","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({attrs:{"ripple":false,"link":"","to":item.href}},on),[_c('v-list-item-icon',[_c('v-icon',{staticClass:"drawer__item-icon"},[_vm._v(" "+_vm._s(item.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"drawer__item-title",attrs:{"id":item.name === 'New Game' ? 'tour-step-8' : ''}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])})],2),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-list',{staticClass:"py-0 mt-2",attrs:{"dense":"","nav":""}},_vm._l((_vm.websiteItems),function(item,index){return _c('v-tooltip',{key:item.name + index,attrs:{"disabled":_vm.isOpen,"nudge-left":"25","color":"black","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({attrs:{"ripple":false,"link":"","href":item.href}},on),[_c('v-list-item-icon',[_c('v-icon',{staticClass:"drawer__item-icon"},[_vm._v(" "+_vm._s(item.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"drawer__item-title"},[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])}),1):_vm._e()],1),_c('div',{staticClass:"drawer__bottom-list"},[_c('v-list',{staticClass:"py-0",attrs:{"dense":"","nav":""}},_vm._l((_vm.bottomNavItems),function(item,index){return _c('v-tooltip',{key:item.name + index,attrs:{"disabled":_vm.isOpen,"nudge-left":"25","color":"black","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({attrs:{"ripple":false,"link":"","target":"_blank","href":item.href}},on),[_c('v-list-item-icon',[_c('v-icon',{staticClass:"drawer__item-icon"},[_vm._v(" "+_vm._s(item.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"drawer__item-title"},[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])}),1),_c('v-list',{staticClass:"py-0",attrs:{"dense":"","nav":""}},[_c('v-tooltip',{attrs:{"disabled":_vm.isOpen,"nudge-left":"25","color":"black","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({attrs:{"ripple":false},on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},on),[_c('v-list-item-icon',[(_vm.isOpen)?_c('v-icon',{staticClass:"drawer__item-icon"},[_vm._v(" mdi-chevron-left-circle-outline ")]):_c('v-icon',{staticClass:"drawer__item-icon"},[_vm._v(" mdi-chevron-right-circle-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"drawer__item-title"},[_vm._v(" Collapse ")])],1)],1)]}}])},[_c('span',[_vm._v("Expand")])])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }