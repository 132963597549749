<template>
  <v-card tile class="login-container">
    <div class="content">
      <div class="header">
        <h2 class="title">
          {{ title }}
        </h2>
        <div @click="handlerClickLogo">
          <TheLogo />
        </div>
      </div>
      <slot />
    </div>
  </v-card>
</template>

<script>
import TheLogo from '@/components/TheLogo'

export default {
  name: 'AntHiveLoginContainer',
  components: {
    TheLogo
  },
  props: {
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      websiteUrl: process.env.VUE_APP_WEBSITE_URL
    }
  },
  methods: {
    handlerClickLogo() {
      window.location.href = this.websiteUrl
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';

.login-container {
  margin: 0 auto;
  display: flex;
  height: 100%;
  max-width: 560px;
  @media screen and (min-width: $screen-sm) {
    margin: 0 auto;
    height: unset;
  }
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;
  }
  .title {
    display: flex;
    align-items: center;
    color: $color-violet-700;
    font-size: $font-bigger;
    font-weight: 500;
    margin-bottom: 15px;
  }
  .content {
    padding: 48px;
    @media screen and (min-width: $screen-sm) {
      width: 100%;
    }
  }
}
</style>
