<template>
  <v-app id="inspire">
    <TheDrawer v-if="isSignedIn" :is-open-mobile-drawer.sync="isOpenMobileDrawer" />
    <v-main class="content">
      <TheHeader v-if="isSignedIn" :is-open-mobile-drawer.sync="isOpenMobileDrawer" />
      <AntHiveTour />
      <TheAlertBar />
      <transition mode="out-in" appear name="fade-transition">
        <router-view />
      </transition>
      <TheFooter v-if="isSignedIn" />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'

import TheFooter from '@/components/TheFooter'
import TheDrawer from '@/components/TheDrawer'
import TheHeader from '@/components/TheHeader'
import AntHiveTour from '@/components/AntHiveTour'
import TheAlertBar from '@/components/TheAlertBar'

export default {
  name: 'TargetPage',
  components: {
    TheFooter,
    TheDrawer,
    TheHeader,
    AntHiveTour,
    TheAlertBar
  },
  data: () => ({
    isOpenMobileDrawer: false
  }),
  computed: {
    ...mapGetters(['getUserDB']),
    isSignedIn() {
      return !!this.getUserDB
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/global.scss';
.error-alert {
  text-transform: capitalize;
  &__report {
    color: $color-violet-700;
    font-weight: 900;
    cursor: pointer;
  }
}
</style>
